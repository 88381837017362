import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { some } from 'modules/common/constants';
import { API_SERVER } from 'modules/common/api';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import SchemaForm from 'modules/common/SchemaForm';
import { filter, flatMap, isEmpty, keyBy, some as lodashSome } from 'lodash';
import { quicklyCreatePrescriptions } from 'modules/reception/component/dental/DentalDetail/Prescription/QuicklyCreatePrescriptions';
import { useFetch } from 'modules/common/hook/useFetch';
import { Medication } from 'modules/schema';
import { useCreateMutate, useDeleteMutate, useUpdateMutate } from 'modules/common/hook/useMutate';
import HistoryPrescriptionDialog from 'modules/reception/component/dental/DentalDetail/Prescription/HistoryPrescriptionDialog';
import PrintIcon from '@mui/icons-material/Print';
import { EncounterDetailCommonProps } from 'modules/examDoctor/type';
import PrescriptionPrintExamDoctorDialog from './PrescriptionPrintExamDoctorDialog';
import { useDialog } from 'modules/common/hook';
import { MEDICATION_CATEGORY_SCOPE, PRESCRIPTION_TYPE, PRESCRIPTION_DETAIL_TYPE } from 'modules/common/apiConstants';
import { prescriptionBox } from './PrescriptionBox';

const PrescriptionFormDataDetail: React.FunctionComponent<EncounterDetailCommonProps> = ({ encounter, editable }) => {
  const { intl, openSnackbar, appState, confirmDialog } = useGeneralHook();
  const [openEncounterForm, onOpenEncounterForm, onCloseEncounterForm] = useDialog();
  const { promptConfirmation, close } = confirmDialog;
  const [openHistoryPescription, setOpenHistory] = useState(false);

  const { data: medicationCategoryList } = useFetch(
    API_SERVER.medicationCategory.getAllFetchCategory({ scope: MEDICATION_CATEGORY_SCOPE.INVENTORY }),
  );

  const medicationCategoryMap = useMemo(() => {
    return keyBy(
      medicationCategoryList?.map((category) => {
        return {
          ...category,
          medicationList: category?.medicationList?.map((medication) => ({ ...medication, categoryId: category?.id })),
        };
      }),
      'id',
    );
  }, [medicationCategoryList]);
  const medicationMap = useMemo(() => {
    return keyBy(
      flatMap(Object.values(medicationCategoryMap), (category) => category?.medicationList),
      'id',
    );
  }, [medicationCategoryMap]);

  // get data list dental prescriptions
  const { data: dataDentalPrescriptions, revalidate: revalidateDentalPrescription } = useFetch(
    API_SERVER.dental.getDentalPrescriptions({
      referenceId: encounter?.id!,
      type: PRESCRIPTION_TYPE.ENCOUNTER,
    }),
  );

  const { data: dataDentalPrescriptionsPatient } = useFetch(
    API_SERVER.dental.getDentalPrescriptionsPatient({
      referenceId: encounter.id!,
      patientId: encounter.patientId!,
      type: PRESCRIPTION_TYPE.ENCOUNTER,
    }),
    { enabled: encounter?.id && encounter?.patientId },
  );

  // get data list dental prescriptions external
  const { data: dataDentalPrescriptionExternal } = useFetch(
    API_SERVER.dental.getDentalPrescriptions({
      referenceId: encounter?.id!,
      type: PRESCRIPTION_TYPE.ENCOUNTER,
    }),
  );

  // Get all Prescription Form Template
  const { data: dataPrescriptionTemplate } = useFetch(API_SERVER.dental.getDentalPrescriptionTemplate());

  const formData = {
    medicationList: dataDentalPrescriptions?.prescriptionDetailList,
    medicationListExternal: dataDentalPrescriptionExternal?.prescriptionDetailList,
  };

  // map data medication list have intendedRoute
  const mappedDataMedicationList = React.useMemo(() => {
    let temp: any[] = [];
    formData?.medicationList?.forEach((i) => {
      const intendedRouteLabel = appState.common.intendedRoutes.find((v) => v.value === i.intendedRoute)?.label;
      const obj = {
        ...i,
        intendedRoute: intendedRouteLabel
          ? {
              value: i.intendedRoute,
              label: intendedRouteLabel,
            }
          : '',
      };
      temp.push(obj);
    });
    temp.sort((a, b) => a.id - b.id);
    return { medicationList: temp };
  }, [appState.common.intendedRoutes, formData?.medicationList]);

  const addMedicationMutate = useCreateMutate({ onSuccess: revalidateDentalPrescription });

  // create item medication
  const onSelectMedication = React.useCallback(
    async (medication: Medication) => {
      if (medication) {
        const medicationAdd = {
          patientId: encounter?.patientId,
          dayNumber: '',
          intendedRoute:
            Object.values(medication?.intendedRoute || {})?.length > 0
              ? Object.values(medication?.intendedRoute || {})[0].value
              : '',
          medicationId: medication?.id,
          medicationName: medication?.name,
          prescriptionType: PRESCRIPTION_TYPE.ENCOUNTER,
          quantity: 1,
          referenceId: encounter?.id!,
          type: PRESCRIPTION_DETAIL_TYPE.MEDICATION_INTERNAL,
          unit: medication?.numeratorUnit,
          unitPerDay: '',
          usage: '',
        };

        addMedicationMutate({
          url: API_SERVER.dental.createDentalPrescriptionsDetail(),
          method: 'POST',
          data: medicationAdd,
        });
      }
    },
    [encounter, addMedicationMutate],
  );

  const onDeleteMutate = useDeleteMutate({ onSuccess: revalidateDentalPrescription });
  const onUpdatPrescriptionsDetail = useUpdateMutate({ onSuccess: revalidateDentalPrescription });
  // update item medication
  const onChangeMedicationInfo = React.useCallback(
    async (medication) => {
      const medicationData = {
        ...medication,
        patientId: encounter?.patientId,
        intendedRoute: medication?.intendedRoute?.value,
        medicationName: medication?.name,
        prescriptionType: PRESCRIPTION_TYPE.ENCOUNTER,
        referenceId: encounter?.id!,
        type: PRESCRIPTION_DETAIL_TYPE.MEDICATION_INTERNAL,
        unit: medication?.numeratorUnit,
      };
      if (editable) {
        onUpdatPrescriptionsDetail({
          url: API_SERVER.dental.updateDentalPrescriptionsDetail(medicationData?.id),
          method: 'PUT',
          data: medicationData,
        });
      }
    },
    [editable, encounter?.id, encounter?.patientId, onUpdatPrescriptionsDetail],
  );

  // data list medication categories all
  const medicationOptionAll = useCallback(() => {
    const arr: any[] = [];
    let categoryList: any = [];
    categoryList = medicationCategoryList;
    categoryList.forEach((i) => {
      i?.medicationList?.forEach((i1: some) => {
        const obj = {
          ...i1,
          group: i.name,
        };
        arr.push(obj);
      });
    });
    return arr;
  }, [medicationCategoryList]);

  // create dental prescriptions clone
  const addPrescriptionsClone = useCreateMutate({ onSuccess: revalidateDentalPrescription });
  const prescriptionsClone = async (data, type) => {
    let prescriptionDetailVOList: some[] = [];
    const dataCloneMap = filter(data.prescriptionDetailList, (item5) =>
      lodashSome(medicationOptionAll(), (arr) => lodashSome(arr, (item100) => item100.id === item5.medicationId)),
    );
    if (type === 'template') {
      data?.detailTemplateList?.forEach(async (i) => {
        prescriptionDetailVOList.push({
          patientId: encounter?.patientId,
          dayNumber: '',
          intendedRoute: i?.intendedRoute,
          medicationId: i?.medicationId,
          medicationName: i?.medicationName,
          prescriptionType: PRESCRIPTION_TYPE.ENCOUNTER,
          quantity: i?.quantity,
          referenceId: encounter?.id!,
          type: PRESCRIPTION_DETAIL_TYPE.MEDICATION_INTERNAL,
          unit: i?.unit,
          unitPerDay: '',
          usage: i?.usage,
        });
      });
    } else {
      dataCloneMap?.forEach(async (i) => {
        prescriptionDetailVOList.push({
          patientId: encounter?.patientId,
          dayNumber: '',
          intendedRoute: i?.intendedRoute,
          medicationId: i?.medicationId,
          medicationName: i?.medicationName,
          prescriptionType: PRESCRIPTION_TYPE.ENCOUNTER,
          quantity: i?.quantity,
          referenceId: encounter?.id!,
          type: i?.type,
          unit: i?.unit,
          unitPerDay: '',
          usage: i?.usage,
        });
      });
    }

    const dataClone = {
      referenceId: encounter?.id!,
      patientId: encounter?.patientId,
      type: PRESCRIPTION_TYPE.ENCOUNTER,
      prescriptionDetailVOList: prescriptionDetailVOList,
      advice: data?.advice,
    };

    addPrescriptionsClone({
      url: API_SERVER.dental.createDentalPrescriptionsClone(),
      method: 'POST',
      data: dataClone,
    });
  };

  // tao nhanh don thuoc tu mau don thuoc
  const addTemplate = async (data) => {
    if (!isEmpty(mappedDataMedicationList?.medicationList)) {
      const confirm = await promptConfirmation({
        title: intl.formatMessage({ id: 'encounterPrescription.confirmTile' }),
        content: intl.formatMessage({ id: 'encounterPrescription.confirmAddTemplate' }),
        okId: 'ok',
      });
      if (confirm) {
        prescriptionsClone(data, 'template');
      }
      close();
    } else {
      prescriptionsClone(data, 'template');
    }
  };

  // tao nhanh don thuoc tu don thuoc cu
  const addTemplateHistory = async (data) => {
    const medicationItem = medicationCategoryList
      ?.flatMap((medication) => medication?.medicationList)
      .map((item) => item?.id);
    data.prescriptionDetailList = data?.prescriptionDetailList?.filter((item) =>
      medicationItem?.includes(item.medicationId),
    );

    if (!isEmpty(mappedDataMedicationList?.medicationList)) {
      const confirm = await promptConfirmation({
        title: intl.formatMessage({ id: 'encounterPrescription.confirmTile' }),
        content: intl.formatMessage({ id: 'encounterPrescription.confirmAddTemplate' }),
        okId: 'ok',
      });
      if (confirm) {
        prescriptionsClone(data, 'previousPrescription');
      }
      close();
    } else {
      prescriptionsClone(data, 'previousPrescription');
    }
    setOpenHistory(false);
  };

  // loi dan cua bac si
  const addDoctorAdvice = useCreateMutate({ onSuccess: revalidateDentalPrescription });
  const doctorAdvice = async (value: string) => {
    const payload = {
      patientId: encounter?.patientId,
      advice: value,
      referenceId: encounter?.id!,
      type: PRESCRIPTION_TYPE.ENCOUNTER,
    };
    addDoctorAdvice({ url: API_SERVER.dental.createDentalPrescriptions(), method: 'POST', data: payload });
  };

  return (
    <>
      <Box bgcolor="white" className="mb10">
        {editable && (
          <>
            <Typography
              className="title"
              fontWeight={500}
              fontSize={'24px'}
              color={'#37474F'}
              padding={'15px 0 5px 10px'}
            >
              <FormattedMessage id="encounterPrescription.applyTemplate" />
            </Typography>
            <Box padding={1} className="dental-medication-prescription">
              <SchemaForm
                schema={quicklyCreatePrescriptions}
                formData={mappedDataMedicationList}
                medicationCategoryList={medicationCategoryList}
                onSelectMedication={onSelectMedication}
                onDelete={(medication) =>
                  onDeleteMutate({
                    url: API_SERVER.dental.deleteDentalPrescriptionsDetail(medication?.id),
                    method: 'DELETE',
                  })
                }
                onChangeMedicationInfo={onChangeMedicationInfo}
                formId="exam-price"
                formProps={{
                  autoComplete: 'nope',
                  autoCorrect: 'off',
                }}
                readOnly={!editable}
                noHelperText={true}
                listTemplate={dataPrescriptionTemplate}
                addTemplate={addTemplate}
                setOpenHistory={setOpenHistory}
              />
            </Box>
            <Typography
              className="title"
              fontWeight={500}
              fontSize={'24px'}
              color={'#37474F'}
              padding={'15px 0 5px 10px'}
            >
              <FormattedMessage id="encounterPrescription.addSingleMedicationRequest" />
            </Typography>
          </>
        )}

        <Box padding={1} className="dental-medication-prescription">
          <SchemaForm
            schema={prescriptionBox}
            formData={mappedDataMedicationList}
            onSelectMedication={onSelectMedication}
            onDelete={(medication) =>
              onDeleteMutate({
                url: API_SERVER.dental.deleteDentalPrescriptionsDetail(medication?.id),
                method: 'DELETE',
              })
            }
            onChangeMedicationInfo={onChangeMedicationInfo}
            formId="exam-price"
            formProps={{
              autoComplete: 'nope',
              autoCorrect: 'off',
            }}
            readOnly={!editable}
            noHelperText={false}
            openSnackbar={openSnackbar}
            confirmDialog={confirmDialog}
            medicationMap={medicationMap}
            medicationCategoryMap={medicationCategoryMap}
          />
        </Box>

        <Typography className="title" fontWeight={500} padding={'10px 0 0 10px'}>
          <FormattedMessage id="encounterPrescription.note" />
        </Typography>
        <Box padding={'8px 8px 30px 8px'}>
          <TextField
            id="outlined-multiline-static"
            placeholder={intl.formatMessage({ id: 'encounterPrescription.noteEnter' })}
            inputProps={{ maxLength: 255 }}
            multiline
            rows={6}
            defaultValue={dataDentalPrescriptions?.advice}
            fullWidth
            InputProps={{
              readOnly: !editable,
            }}
            onBlur={(e) => {
              if (e.target.value?.trim() && editable) {
                doctorAdvice(e.target.value?.trim());
              }
            }}
          />
          {!editable && (
            <Typography variant="caption" color="textSecondary" sx={{ fontSize: '12px' }}>
              <FormattedMessage id="readOnly" />
            </Typography>
          )}
        </Box>
      </Box>
      <Box component="form" onSubmit={(e) => console.log(e)} bgcolor="white">
        <Box>
          <Box paddingY={2} gap={2} display="flex" justifyContent={'flex-end'}>
            <Button
              startIcon={<PrintIcon />}
              variant="contained"
              color="inherit"
              style={{ width: 180 }}
              disabled={
                dataDentalPrescriptions?.prescriptionDetailList &&
                dataDentalPrescriptions?.prescriptionDetailList?.length === 0
              }
              onClick={() => {
                onOpenEncounterForm();
              }}
            >
              <FormattedMessage id="encounterPrescription.print" />
            </Button>
          </Box>
        </Box>
      </Box>
      <HistoryPrescriptionDialog
        open={openHistoryPescription}
        onClose={() => {
          setOpenHistory(false);
        }}
        onSubmit={addTemplateHistory}
        dataDentalPrescriptionsPatient={dataDentalPrescriptionsPatient || []}
      />
      <PrescriptionPrintExamDoctorDialog
        open={openEncounterForm}
        encounter={encounter}
        prescription={dataDentalPrescriptions}
        onClose={onCloseEncounterForm}
        presNote={dataDentalPrescriptionExternal?.advice}
      />
    </>
  );
};

export default PrescriptionFormDataDetail;
