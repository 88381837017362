import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  alpha,
  Box,
  BoxProps,
  Button,
  Checkbox,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableBodyProps,
  TableCell as TableCellRaw,
  TableCellProps,
  TableContainerProps,
  TableHead,
  TablePagination,
  TablePaginationProps,
  TableProps,
  TableRow as TableRowRaw,
  TableRowProps,
  Theme,
  Typography,
  Stack,
  Tooltip,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { some } from '../../constants';
import LoadingIcon from '../LoadingIcon';
import { TableCustomCell, TableCustomContainer, TableCustomRow, TableHeaderCell } from './element';
import './styles.scss';
import TablePaginationActionsCustom from './TablePaginationActionsCustom';
import TableRowWrapper from './TableRowWrapper';
import { getColumns, getFlatDataSource, getWidthHeader, renderColumnHeader } from './utils';
import { NestedKeyOf } from 'modules/common/type';
import ErrorIcon from '@mui/icons-material/Error';

function useStickyResult(value) {
  const val = useRef<some[]>([]);
  if (value !== undefined) val.current = value;
  return val.current || [];
}

const useStyles = makeStyles((theme: Theme) => ({
  indeterminateColor: {
    color: theme.palette.primary.main,
  },
}));

export interface Columns<T extends object = any> extends some {
  title?: React.ReactNode;
  dataIndex?: NestedKeyOf<T> | string;
  key?: string;
  align?: TableCellProps['align'];
  rowSpan?: TableCellProps['rowSpan'];
  colSpan?: TableCellProps['colSpan'];
  props?: ((record: T) => TableCellProps) | TableCellProps;
  headerProps?: TableCellProps;
  render?: (col: T, index: number) => JSX.Element | string;
  fixed?: 'right' | 'left';
  width?: number | string;
  minWidth?: number | string;
  hidden?: boolean;
  disableAction?: boolean;
  lastCell?: LastCell;
  children?: Columns[];
  ellipsis?: boolean;
  default?: boolean;
  iconTooltip?: boolean;
  titleToolTip?: string;
}

interface LastCell extends Omit<Columns, 'render'> {
  render?: (data: some[], selectedRowKeys?: some[]) => JSX.Element;
}
interface RowSelection {
  key?: string;
  columnTitle?: React.ReactNode;
  columnWidth?: string | number;
  fixed?: boolean;
  hideSelectAll?: boolean;
  selectedRowKeys?: some[];
  onSelect?: (params: { record: some; selected: boolean; selectedRows: some[] }) => void;
  onSelectAll?: (params: { changeRows: some[]; selected: boolean; selectedRows: some[] }) => void;
  onChange?: (params: { selectedRowKeys: (string | number)[]; selectedRows: some[] }) => void;
  render?: (
    params: {
      record: some;
      selected: boolean;
      selectedRows: some[];
      onChange: (selected: boolean) => void;
    },
    defaultContent: React.ReactNode,
  ) => React.ReactNode;
  hidden?: (record: some) => boolean;
  disableSelectAll?: boolean;
}

interface Props<T> {
  tableProps?: TableProps;
  containerProps?: TableContainerProps;
  boxProps?: BoxProps;
  bodyProps?: TableBodyProps;
  rowProps?: (col: T, index: number) => TableRowProps;
  dataSource?: T[];
  columns: Columns[];
  paginationProps?: TablePaginationProps;
  loading?: boolean;
  hideColumnIndex?: boolean;
  fixIndexColumn?: boolean;
  hiddenHeader?: boolean;
  isLoadDone?: boolean;
  loadMore?: () => void;
  rowSelection?: RowSelection;
  caption?: React.ReactNode;
  footer?: React.ReactNode;
  showAll?: boolean;
  hiddenFirstColumnCollapse?: boolean;
  expandable?: {
    expandedRowRender: (record: some, index: number, action: some) => React.ReactNode;
    rowExpandable?: (record: some, index: number) => boolean;
  };
  keyName?: string;
}

export const TableCustom: <T extends some | null>(propTable: Props<T>) => React.ReactElement<Props<T>> = (
  propTable,
) => {
  const {
    dataSource: data = [],
    columns,
    tableProps,
    containerProps,
    bodyProps,
    rowProps,
    paginationProps,
    loading,
    hideColumnIndex,
    fixIndexColumn,
    hiddenHeader,
    loadMore,
    isLoadDone,
    rowSelection,
    caption,
    boxProps,
    showAll,
    hiddenFirstColumnCollapse,
    footer,
    expandable,
    keyName,
  } = propTable;
  const intl = useIntl();
  const classes = useStyles();
  const [selection, setSelection] = useState<some[]>([]);
  const keySelection = rowSelection?.key || 'id';
  const container = useRef<HTMLDivElement>(null);
  const dataSource = useStickyResult(data);

  const getRowIndex = useCallback(
    (i: number) => {
      let index = i;
      if (paginationProps) {
        index += paginationProps.page * paginationProps.rowsPerPage;
      }
      return index;
    },
    [paginationProps],
  );

  const getDataSource = useMemo(() => {
    const temp = dataSource
      ? dataSource.map((v, index) => {
          return { ...v, index: getRowIndex(index + 1) };
        })
      : [];
    return temp;
  }, [dataSource, getRowIndex]);

  const flatDataSource = useMemo(() => {
    const temp = dataSource ? getFlatDataSource(dataSource as some[]) : [];
    return temp;
  }, [dataSource]);

  const setSelectionFnc = useCallback(
    (record: some, checkedValue: boolean) => {
      if (!rowSelection) {
        return;
      }
      const { onSelect, onChange } = rowSelection;
      const keyValue = record[keySelection];
      const tmp = checkedValue ? [...selection, record] : selection.filter((v) => v[keySelection] !== keyValue);
      setSelection(tmp);
      onChange &&
        onChange({
          selectedRowKeys: tmp.map((v) => v[keySelection]),
          selectedRows: tmp,
        });
      onSelect &&
        onSelect({
          record: record,
          selected: checkedValue,
          selectedRows: tmp,
        });
    },
    [keySelection, rowSelection, selection],
  );

  const { getFlatColumn, columnsRaw } = useMemo(() => {
    let tmp = columns.filter((column) => !column.hidden);

    if (!hideColumnIndex) {
      tmp = [
        {
          title: 'stt',
          dataIndex: 'index',
          fixed: fixIndexColumn ? 'left' : undefined,
          width: 48,
          minWidth: 48,
          align: 'center',
        } as Columns,
        ...tmp,
      ];
    }
    if (rowSelection) {
      const {
        columnWidth,
        columnTitle,
        onSelectAll,
        onChange,
        hideSelectAll,
        fixed,
        render,
        hidden,
        disableSelectAll,
      } = rowSelection;
      const dataTmp = hidden ? flatDataSource.filter((v) => !hidden(v)) : flatDataSource;
      const checkedAll =
        dataTmp?.filter((value) => {
          return selection.find((v) => v[keySelection] === value[keySelection]);
        }).length === dataTmp.length && dataTmp.length > 0;
      tmp = [
        {
          fixed: fixed ? 'left' : undefined,
          width: columnWidth || 48,
          align: 'center',
          disableAction: true,
          props: { style: { padding: 8 } },
          headerProps: { style: { padding: 8 } },
          title:
            columnTitle ||
            (!hideSelectAll && (
              <Checkbox
                classes={{ indeterminate: classes.indeterminateColor }}
                style={{ padding: 0 }}
                disableTouchRipple
                disableRipple
                disableFocusRipple
                checked={checkedAll}
                disabled={!dataTmp.length || disableSelectAll}
                indeterminate={selection?.length > 0 && !checkedAll}
                color="primary"
                onClick={(event) => {
                  event.stopPropagation();
                }}
                onChange={(event) => {
                  const checkedValue = event.target.checked;
                  const tmp = checkedValue ? flatDataSource.filter((val) => (hidden ? !hidden(val) : true)) : [];
                  setSelection(tmp);
                  onChange &&
                    onChange({
                      selectedRowKeys: tmp.map((v) => v[keySelection]),
                      selectedRows: tmp,
                    });
                  onSelectAll &&
                    onSelectAll({
                      selected: checkedValue,
                      selectedRows: tmp,
                      changeRows: checkedValue
                        ? flatDataSource.filter((val) => !selection.find((v) => v[keySelection] === val[keySelection]))
                        : flatDataSource,
                    });
                }}
              />
            )),
          renderCore: (record) => {
            const keyValue = record[keySelection];
            const checked = !!selection.find((v) => v[keySelection] === keyValue);
            const content =
              hidden && hidden(record) ? null : (
                <Checkbox
                  style={{ padding: 0, display: 'inherit' }}
                  disableTouchRipple
                  disableRipple
                  disableFocusRipple
                  disabled={record?.disabled}
                  color="primary"
                  checked={checked}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  onChange={(event) => {
                    const checkedValue = event.target.checked;
                    setSelectionFnc(record, checkedValue);
                  }}
                />
              );
            return typeof render === 'function'
              ? render(
                  {
                    record,
                    onChange: (selected: boolean) => setSelectionFnc(record, selected),
                    selected: checked,
                    selectedRows: selection,
                  },
                  content,
                )
              : content;
          },
        } as Columns,
        ...tmp,
      ];
    }
    if (expandable) {
      tmp = [
        {
          title: '',
          fixed: 'left',
          width: 48,
          align: 'center',
          renderCore: (record, index, { state, setState }) => {
            if (expandable.rowExpandable ? expandable.rowExpandable(record, index) : true) {
              return (
                <IconButton
                  onClick={() => {
                    setState((old) => ({ ...old, openExpand: !old.openExpand }));
                  }}
                  style={{
                    padding: 0,
                  }}
                >
                  <KeyboardArrowDownIcon
                    style={{
                      transform: state.openExpand ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: 'all 0.3s',
                      fontSize: 24,
                    }}
                  />
                </IconButton>
              );
            } else {
              return null;
            }
          },
        } as Columns,
        ...tmp,
      ];
    }

    if (dataSource?.findIndex((v) => v?.children && v?.children?.length > 0) !== -1 && !hiddenFirstColumnCollapse) {
      tmp = [
        {
          title: '',
          fixed: 'left',
          width: 48,
          align: 'center',
          renderCore: (record, index, { state, setState }) => {
            return (
              record.children && (
                <IconButton
                  style={{
                    padding: 0,
                  }}
                  onClick={() => {
                    setState((old) => ({ ...old, openChildren: !old.openChildren }));
                  }}
                >
                  <KeyboardArrowRightIcon
                    style={{
                      transform: state.openChildren ? 'rotate(90deg)' : 'rotate(0deg)',
                      transition: 'all 0.3s',
                      fontSize: 24,
                    }}
                  />
                </IconButton>
              )
            );
          },
        } as Columns,
        ...tmp,
      ];
    }
    return { getFlatColumn: getColumns(tmp), columnsRaw: tmp };
  }, [
    columns,
    hideColumnIndex,
    rowSelection,
    dataSource,
    hiddenFirstColumnCollapse,
    expandable,
    fixIndexColumn,
    flatDataSource,
    classes.indeterminateColor,
    selection,
    keySelection,
    setSelectionFnc,
  ]);

  const getWidth = useCallback(
    (col: Columns) => {
      // await new Promise(f => setTimeout(f, 300));
      const { fixed, title } = col;
      let width = 0;
      if (fixed) {
        const columnsTmp = fixed === 'left' ? [...getFlatColumn] : [...getFlatColumn]?.reverse();
        for (let i = 0; i < columnsTmp.length; i += 1) {
          if (title === columnsTmp[i]?.title) {
            break;
          }
          const tmp = columnsTmp[i]?.width;
          const fixedTmp = columnsTmp[i]?.fixed;
          if (typeof tmp === 'number' && fixedTmp) {
            width += tmp || 0;
          }
        }
      }
      return width;
    },
    [getFlatColumn],
  );

  const getIndexFixedColumnRight = useMemo(() => {
    const columnsTmp = [...getFlatColumn];
    const index = columnsTmp.findIndex((v) => v.fixed === 'right');
    return index;
  }, [getFlatColumn]);

  const getIndexFixedColumnLeft = useMemo(() => {
    const columnsTmp = [...getFlatColumn]?.reverse();
    const index = columnsTmp.findIndex((v) => v.fixed === 'left');
    return columnsTmp.length - index - 1;
  }, [getFlatColumn]);

  const getBodyContent = useCallback(
    (data: some[]) => {
      return data.map((items: any, index: number) => {
        return (
          <TableRowWrapper
            key={keyName ? items[keyName] : items.id || index}
            defaultState={{ openChildren: hiddenFirstColumnCollapse || showAll }}
          >
            {(state, setState) => (
              <>
                <TableCustomRow {...(rowProps && rowProps(items, index))}>
                  {getFlatColumn.map((col: Columns, i: number) => {
                    const { props, fixed, rowSpan, colSpan, render, dataIndex, disableAction, align, renderCore } = col;
                    const propsCell = typeof props === 'function' ? props(items) : props;
                    const renderValue = dataIndex ? get(items, dataIndex, '') : '';

                    if (propsCell?.colSpan === 0 || colSpan === 0) {
                      return null;
                    }
                    return (
                      <TableCustomCell
                        key={i}
                        onClick={(e) => {
                          disableAction && e.stopPropagation();
                        }}
                        {...{ align, colSpan, rowSpan, ...propsCell }}
                        className={`${
                          fixed === 'left' && getIndexFixedColumnLeft === i
                            ? 'table-cell-fix-left-last'
                            : fixed === 'right' && getIndexFixedColumnRight === i
                            ? 'table-cell-fix-right-last'
                            : ''
                        } ${propsCell?.className || ''}`}
                        style={{
                          ...propsCell?.style,
                          position: fixed ? 'sticky' : undefined,
                          left: fixed === 'left' ? getWidth(col) : undefined,
                          right: fixed === 'right' ? getWidth(col) : undefined,
                          zIndex: fixed ? 10 : undefined,
                        }}
                      >
                        {renderCore && renderCore(items, index, { state, setState })}
                        {render ? (
                          <>{render(items, index)}</>
                        ) : (
                          <>
                            {renderValue && <Typography variant={'body1'}>{Object(renderValue).toString()}</Typography>}
                          </>
                        )}
                      </TableCustomCell>
                    );
                  })}
                </TableCustomRow>
                {expandable && (
                  <TableCustomRow {...(rowProps && rowProps(items, index))}>
                    <TableCustomCell
                      colSpan={getFlatColumn?.reduce((v, c) => v + (c?.colSpan || 1), 1)}
                      style={{ padding: 0, border: state.openExpand ? undefined : 'none' }}
                    >
                      <Collapse in={state.openExpand} timeout="auto" unmountOnExit>
                        {expandable.expandedRowRender &&
                          expandable.expandedRowRender(items, index, { state, setState })}
                      </Collapse>
                    </TableCustomCell>
                  </TableCustomRow>
                )}
                {state.openChildren && items.children?.length > 0 && getBodyContent(items.children)}
              </>
            )}
          </TableRowWrapper>
        );
      });
    },
    [
      expandable,
      getFlatColumn,
      getIndexFixedColumnLeft,
      getIndexFixedColumnRight,
      getWidth,
      hiddenFirstColumnCollapse,
      keyName,
      rowProps,
      showAll,
    ],
  );

  useEffect(() => {
    setSelection((old) => rowSelection?.selectedRowKeys || old);
  }, [rowSelection?.selectedRowKeys]);

  useEffect(() => {
    if (
      container.current?.offsetWidth &&
      container.current?.scrollWidth &&
      container.current.offsetWidth < container.current.scrollWidth
    ) {
      container.current.classList.add('table-custom-has-fix-right');
    }
  }, []);

  return (
    <Box
      position="relative"
      overflow="inherit"
      display="flex"
      flexDirection="column"
      height="-webkit-fill-available"
      flex={1}
      {...boxProps}
    >
      <TableCustomContainer
        ref={container}
        {...containerProps}
        className={`${containerProps?.className} table-custom`}
        onScrollCapture={(e) => {
          if (!container.current) {
            return;
          }
          if (e.currentTarget.scrollLeft) {
            container.current.classList.add('table-custom-has-fix-left');
          } else {
            container.current.classList.remove('table-custom-has-fix-left');
          }
          if (Math.round(e.currentTarget.scrollWidth - e.currentTarget.clientWidth - e.currentTarget.scrollLeft) > 0) {
            container.current.classList.add('table-custom-has-fix-right');
          } else {
            container.current.classList.remove('table-custom-has-fix-right');
          }
        }}
      >
        <Table stickyHeader {...tableProps} style={{ borderCollapse: 'collapse', ...tableProps?.style }}>
          {!hiddenHeader && (
            <TableHead sx={{ position: 'sticky', top: 0, zIndex: 40 }}>
              {renderColumnHeader(columnsRaw).map((rowHeader: Columns[], index: number) => {
                return (
                  <TableCustomRow key={index}>
                    {rowHeader.map((colHeader: Columns, idx: number) => {
                      const {
                        fixed,
                        width,
                        minWidth,
                        align,
                        rowSpan,
                        colSpan,
                        headerProps,
                        title,
                        ellipsis,
                        leftIndexFixed,
                        rightIndexFixed,
                        iconTooltip,
                        titleToolTip,
                      } = colHeader;
                      return (
                        <TableHeaderCell
                          align={align}
                          key={idx}
                          rowSpan={rowSpan}
                          colSpan={colSpan}
                          {...headerProps}
                          className={`${
                            leftIndexFixed
                              ? 'table-cell-fix-left-last'
                              : rightIndexFixed
                              ? 'table-cell-fix-right-last'
                              : ''
                          } ${headerProps?.className || ''}`}
                          style={{
                            ...headerProps?.style,
                            width: width,
                            minWidth: fixed ? width : minWidth,
                            position: fixed ? 'sticky' : undefined,
                            left: fixed === 'left' ? getWidthHeader(colHeader, rowHeader) : undefined,
                            right: fixed === 'right' ? getWidthHeader(colHeader, rowHeader) : undefined,
                            textTransform: 'uppercase',
                            zIndex: fixed ? 30 : 20,
                          }}
                        >
                          <Stack direction={'row'} gap="10px" flex={1} alignItems={'center'} display={'block'}>
                            {typeof title === 'string'
                              ? title && (
                                  <Typography variant="caption" style={{ fontWeight: 'bold' }} noWrap={ellipsis}>
                                    <FormattedMessage id={title} />
                                  </Typography>
                                )
                              : title}
                            {iconTooltip && (
                              <Tooltip title={intl.formatMessage({ id: titleToolTip })}>
                                <ErrorIcon
                                  sx={{
                                    color: '#7b7b80',
                                    height: '15px',
                                  }}
                                />
                              </Tooltip>
                            )}
                          </Stack>
                        </TableHeaderCell>
                      );
                    })}
                  </TableCustomRow>
                );
              })}
            </TableHead>
          )}
          <TableBody {...bodyProps}>
            {getDataSource.length === 0 && (
              <TableRowRaw>
                <TableCustomCell colSpan={getFlatColumn.length}>
                  {caption ? (
                    caption
                  ) : (
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '100%',
                      }}
                    >
                      {!loading && (
                        <Typography variant="inherit" color="textSecondary">
                          <FormattedMessage id="noData" />
                        </Typography>
                      )}
                    </Box>
                  )}
                </TableCustomCell>
              </TableRowRaw>
            )}
            {getBodyContent(getDataSource)}
            <TableCustomRow
              className="extendRow"
              sx={{
                zIndex: 10,
                position: 'sticky',
                bottom: 0,
                background: 'white',
                [`@media print`]: {
                  position: 'unset',
                },
              }}
            >
              {getDataSource &&
                getDataSource?.length > 0 &&
                getFlatColumn
                  .filter((v) => v.lastCell)
                  .map((col: Columns, index: number) => {
                    const { lastCell, disableAction, fixed } = col;
                    const { render, align, colSpan, rowSpan, props } = lastCell || {};
                    return (
                      <TableCustomCell
                        key={index}
                        onClick={(e) => {
                          disableAction && e.stopPropagation();
                        }}
                        {...{ align, colSpan, rowSpan, ...props }}
                        style={{
                          ...props?.style,
                          ...lastCell?.style,
                          position: fixed ? 'sticky' : undefined,
                          left: fixed === 'left' ? getWidth(col) : undefined,
                          right: fixed === 'right' ? getWidth(col) : undefined,
                          // background: 'unset',
                        }}
                        className={`extendCell ${
                          fixed === 'left' && getIndexFixedColumnLeft === index
                            ? 'table-cell-fix-left-last'
                            : fixed === 'right' && getIndexFixedColumnRight === index
                            ? 'table-cell-fix-right-last'
                            : ''
                        } ${props?.className || ''}`}
                      >
                        {lastCell && <>{render && render(getDataSource, selection)}</>}
                      </TableCustomCell>
                    );
                  })}
            </TableCustomRow>
            {loadMore && !isLoadDone && getDataSource.length !== 0 && (
              <TableRowRaw key="loadMore">
                <TableCellRaw colSpan={getFlatColumn.length} align="center" style={{ border: 'none' }}>
                  <Button color="primary" variant="text" onClick={loadMore}>
                    <FormattedMessage id="loadMore" />
                  </Button>
                </TableCellRaw>
              </TableRowRaw>
            )}
            {footer}
          </TableBody>
        </Table>
      </TableCustomContainer>

      {loading && container.current && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 5,
            minHeight: 400,
            background: alpha(grey[300], 0.7),
          }}
        >
          <LoadingIcon />
        </div>
      )}
      {paginationProps && (
        <TablePagination
          component="div"
          data-tour="step-5"
          style={{ padding: 4 }}
          labelRowsPerPage={intl.formatMessage({ id: 'labelRowPerPage' })}
          ActionsComponent={TablePaginationActionsCustom}
          {...paginationProps}
          page={paginationProps.page || 0}
          count={paginationProps.count || 0}
        />
      )}
    </Box>
  );
};

export default TableCustom;
