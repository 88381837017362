import { Notifications } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { EditIcon, WarningAmber, WarningAmberR } from 'svg';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import LoadingIcon from 'modules/common/component/LoadingIcon';
import { TableCustomCell, TableCustomRow, TableHeaderCell } from 'modules/common/component/TableCustom/element';
import TablePaginationActionsCustom from 'modules/common/component/TableCustom/TablePaginationActionsCustom';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { HookPaginationProps } from 'modules/common/hook/usePaginationHook';
import { MEDICATION_UNIT } from './constant';
import MedicationUnitSettingDialog from './MedicationUnitSettingDialog';
import { API_SERVER } from 'modules/common/api';
import { axiosThunk } from 'modules/common/redux/axios';
import { AxiosError } from 'axios';
import { NumericFormatText } from 'modules/common/utils';

const Row: React.FunctionComponent<{
  row: some;
  onNoti: (value) => void;
  index: number;
  onButtonSettingClick: (medication: some, lot: some) => void;
}> = (props) => {
  const { row, onNoti, onButtonSettingClick } = props;
  const intl = useIntl();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableCustomRow>
        <TableCustomCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            <KeyboardArrowDownIcon
              style={{
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'all 200ms',
                fontSize: 24,
              }}
            />
          </IconButton>
        </TableCustomCell>
        <TableCustomCell>
          <Typography variant="subtitle1">{row.medicationId}</Typography>
        </TableCustomCell>
        <TableCustomCell>
          <Typography variant="subtitle1">{row.name}</Typography>
        </TableCustomCell>
        <TableCustomCell>
          <Typography
            variant="subtitle1"
            color={
              row.quantityStatus === 'WARNING' || row.quantityStatus === 'OUT_OF_STOCK' ? 'error.light' : undefined
            }
          >
            {row.unitQuantityList
              .map((v) => {
                const label = MEDICATION_UNIT.find((one) => one.value === v.unit)?.label;
                return `${v.quantity} ${label && intl.formatMessage({ id: label })}`;
              })
              ?.join(', ')}
            {(row.quantityStatus === 'WARNING' || row.quantityStatus === 'OUT_OF_STOCK') && (
              <WarningAmberR style={{ display: 'inline-flex', verticalAlign: 'top', marginLeft: '5px', fill: 'red' }} />
            )}
          </Typography>
        </TableCustomCell>

        <TableCustomCell style={{ textAlign: 'end' }}>
          <IconButtonTitle
            title="inventory.alert.config"
            size="small"
            onClick={() => {
              onNoti(row);
            }}
          >
            <Notifications />
          </IconButtonTitle>
        </TableCustomCell>
      </TableCustomRow>
      <TableRow style={{ backgroundColor: 'white', padding: 0 }}>
        <TableCustomCell style={{ padding: '0px', border: 'none', backgroundColor: 'inherit' }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases" style={{ marginTop: 4 }}>
              <TableHead>
                <TableRow>
                  <TableHeaderCell style={{ width: 214 }} />
                  <TableHeaderCell style={{ color: 'grey', width: '20%' }}>
                    <FormattedMessage id="inventory.lot" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey', width: '15%' }}>
                    <FormattedMessage id="expiredDate" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey', width: '15%' }}>
                    <FormattedMessage id="inventory.remainAmount" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="label.inventory.averageImportPrice" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="inventory.price" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}></TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {row?.medicationPackageList?.map((record: some, index) => {
                  return (
                    <TableCustomRow key={index}>
                      <TableCustomCell />
                      <TableCustomCell>{record.lot}</TableCustomCell>
                      <TableCustomCell>
                        <Typography
                          variant="body1"
                          color={
                            record.expiredStatus === 'WARNING'
                              ? 'warning.light'
                              : record.expiredStatus === 'OUT_OF_DATE'
                              ? 'error.light'
                              : undefined
                          }
                        >
                          {record.expiredDate && moment(record.expiredDate, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
                          {record.expiredStatus === 'WARNING' && (
                            <WarningAmber
                              style={{ display: 'inline-flex', verticalAlign: 'top', marginLeft: '5px', fill: 'red' }}
                            />
                          )}
                          {record.expiredStatus === 'OUT_OF_DATE' && (
                            <WarningAmberR
                              style={{ display: 'inline-flex', verticalAlign: 'top', marginLeft: '5px', fill: 'red' }}
                            />
                          )}
                        </Typography>
                      </TableCustomCell>
                      <TableCustomCell>
                        <Typography variant="body1">
                          {record.unitQuantityList
                            ?.map((v) => {
                              const label = MEDICATION_UNIT.find((one) => one.value === v.unit)?.label;
                              return `${v.quantity} ${label && intl.formatMessage({ id: label })}`;
                            })
                            ?.join(', ')}
                        </Typography>
                      </TableCustomCell>
                      <TableCustomCell>{NumericFormatText(record.averagePrice)}</TableCustomCell>
                      <TableCustomCell>
                        <Typography variant="body1">
                          {record.unitConverterList
                            ?.sort((e1, e2) => e1?.factor - e2?.factor)
                            .map((v, index) => {
                              const label = MEDICATION_UNIT.find((one) => one.value === v.fromUnit)?.label;
                              return (
                                <span key={index}>
                                  <strong>{`1 ${label && intl.formatMessage({ id: label })}: `}</strong>
                                  {NumericFormatText(v.price)};&nbsp;
                                </span>
                              );
                            })}
                        </Typography>
                      </TableCustomCell>
                      <TableCustomCell style={{ textAlign: 'right', paddingRight: '10px' }}>
                        <IconButtonTitle
                          title="inventory.unitSetting.tooltip.editPrice"
                          size="small"
                          onClick={() => {
                            onButtonSettingClick(row, record);
                          }}
                          sx={{ width: '34px', height: '34px' }}
                        >
                          <EditIcon />
                        </IconButtonTitle>
                      </TableCustomCell>
                    </TableCustomRow>
                  );
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCustomCell>
      </TableRow>
    </>
  );
};

interface Props {
  data: some;
  onNoti: (value) => void;
  hookPagination: HookPaginationProps;
  loading?: boolean;
  revalidateTableData: () => void;
}

const InventoryTable: React.FunctionComponent<Props> = (props) => {
  const { data, hookPagination, loading, onNoti, revalidateTableData } = props;
  const { intl, dispatch, openSnackbar } = useGeneralHook();
  const { onPageChange, onRowsPerPageChange, pagination } = hookPagination;
  const [showMedicationSettingDialog, setShowMedicationSettingDialog] = useState(false);
  const [selectedLot, setSelectedLot] = useState<some | null>(null);

  const onSubmit = async (formValue) => {
    try {
      const json = await dispatch(
        axiosThunk({
          url: API_SERVER.medicationUnitSetting.updateMedicationUnitSettings(),
          method: 'PUT',
          data: {
            data: [
              { id: formValue?.basicSetting?.id, price: formValue.basicPrice },
              ...formValue?.unitConverterList?.map((setting) => ({ id: setting?.id, price: setting?.price })),
            ],
          },
        }),
      );
      if (json?.status === 200) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
        revalidateTableData();
        setShowMedicationSettingDialog(false);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          error.response?.data?.errors.forEach((v) => {
            openSnackbar({ message: v.message, type: 'error' });
          });
        }
      }
    }
  };

  const onButtonSettingClick = async (medication, lot) => {
    try {
      const { data } = await dispatch(
        axiosThunk({
          url: API_SERVER.medicationUnitSetting.getMedicationUnitSettings(medication?.medicationId, { lot: lot?.lot })
            .url,
        }),
      );

      if (!data || data.length === 0) {
        openSnackbar({
          message: intl.formatMessage({ id: 'inventory.unitSetting.error.unknownError' }),
          type: 'error',
        });
        return;
      }

      setSelectedLot(
        data
          ?.sort((e1, e2) => e1?.factor - e2?.factor)
          ?.reduce(
            (pre, currentValue) => {
              if (currentValue?.isBasicUnit) {
                return { ...pre, basicSetting: { ...currentValue }, basicPrice: currentValue?.price || 0 };
              }
              return { ...pre, unitConverterList: [...pre.unitConverterList, currentValue] };
            },
            { ...medication, ...lot, unitConverterList: [] },
          ),
      );

      setShowMedicationSettingDialog(true);
    } catch (error) {
      openSnackbar({ message: intl.formatMessage({ id: 'inventory.unitSetting.error.unknownError' }), type: 'error' });
    }
  };

  return (
    <Box
      position="relative"
      overflow="inherit"
      display="flex"
      flexDirection="column"
      height="-webkit-fill-available"
      flex={1}
    >
      <TableContainer style={{ height: '100%' }}>
        <Table stickyHeader>
          <TableHead>
            <TableCustomRow>
              <TableHeaderCell style={{ width: 54 }} />
              <TableHeaderCell style={{ width: 160 }}>
                <FormattedMessage id="ID" />
              </TableHeaderCell>
              <TableHeaderCell width="35%">
                <FormattedMessage id="inventory.drugName" />
              </TableHeaderCell>
              <TableHeaderCell>
                <FormattedMessage id="inventory.summary" />
              </TableHeaderCell>
              <TableHeaderCell style={{ width: 120 }} />
            </TableCustomRow>
          </TableHead>
          <TableBody>
            {data?.content?.length > 0 ? (
              data?.content?.map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  onNoti={onNoti}
                  index={index + 1}
                  onButtonSettingClick={onButtonSettingClick}
                />
              ))
            ) : (
              <>
                <TableCustomCell colSpan={5}>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minHeight: '100%',
                    }}
                  >
                    {loading ? (
                      <LoadingIcon />
                    ) : (
                      <Typography variant="inherit" color="textSecondary">
                        <FormattedMessage id="noData" />
                      </Typography>
                    )}
                  </Box>
                </TableCustomCell>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        data-tour="step-5"
        style={{ padding: 4 }}
        labelRowsPerPage={intl.formatMessage({ id: 'labelRowPerPage' })}
        ActionsComponent={TablePaginationActionsCustom}
        count={data?.pagination?.totalElements}
        page={pagination.page}
        rowsPerPage={pagination.pageSize}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
      {!!selectedLot && (
        <MedicationUnitSettingDialog
          open={showMedicationSettingDialog}
          lot={selectedLot}
          medication={data}
          onClose={() => {
            setShowMedicationSettingDialog(false);
            setSelectedLot(null);
          }}
          onSubmit={onSubmit}
        />
      )}
    </Box>
  );
};

export default InventoryTable;
