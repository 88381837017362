import { Button, DialogActions, DialogContent, Typography } from '@mui/material';
import {
  BE_DATE_FORMAT,
  BE_DATE_TIME_FORMAT,
  DENTAL_EXAM_STATUS,
  FE_DATE_FORMAT,
  some,
} from '../../../common/constants';
import { HookPaginationProps } from 'modules/common/hook/usePaginationHook';
import * as React from 'react';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { API_SERVER } from 'modules/common/api';
import { FormattedMessage } from 'react-intl';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { useHistory } from 'react-router';
import moment from 'moment';
import { useEditPICSelectors } from '../../../reception/component/encounter/EditPICDialog/state';
import { ROUTES } from '../../../layout/router';
import { axiosThunk } from '../../../common/redux/axios';
import DialogCustom from '../../../common/component/DialogCustom';
import IconButtonTitle from '../../../common/component/IconButtonTitle';
import { DoctorExchange } from '../../../../svg';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../redux/reducer';
import EditPICDialog from '../../../reception/component/encounter/EditPICDialog';
import { PATIENT_TYPE } from 'modules/common/apiConstants';

interface IEncounterListProps {
  data?: some[];
  loading: boolean;
  hookPagination: HookPaginationProps;
  total: number;
  refresh: () => void;
}

const DentalExamList: React.FunctionComponent<IEncounterListProps> = ({
  refresh,
  data,
  loading,
  hookPagination,
  total,
}) => {
  const { dispatch, intl, appState, confirmDialog } = useGeneralHook();
  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;
  const history = useHistory();
  const [reason, setReason] = React.useState('encounterDetail.outTime');
  const [open, setOpen] = React.useState(false);
  const currentUserId = useSelector((state: AppState) => state?.authen?.user?.user_id);

  const onAcceptDentalExam = React.useCallback(
    async (dentalExamId: some) => {
      await dispatch(
        axiosThunk({
          url: API_SERVER.dental.accept(dentalExamId),
          method: 'post',
          data: { data: data },
        }),
      );
    },
    [dispatch, data],
  );

  const columns = React.useMemo(() => {
    return [
      {
        title: 'encounter.table.label.sequenceNumber',
        dataIndex: 'sequenceNumber',
        align: 'left',
        width: 90,
      },
      {
        width: 120,
        title: 'encounterId',
        dataIndex: 'code',
      },

      {
        width: 120,
        title: 'patientCodeShort',
        render: (record) => {
          return (
            <Typography>{record?.patient?.code?.split('-')?.[1] || record?.patient?.patientResourceId}</Typography>
          );
        },
      },
      {
        title: 'patientName',
        dataIndex: 'patient.name',
      },

      {
        title: 'birthdayShort',
        render: (record) => (
          <Typography>
            {record.patient?.dob && moment(record.patient.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
          </Typography>
        ),
        width: 120,
      },

      {
        title: 'telephone',
        dataIndex: 'patient.mobilePhone',
      },

      {
        width: 110,
        title: 'encounterDate',
        render: (record) => (
          <Typography>
            {record.createdTime && moment(record.createdTime, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT)}
          </Typography>
        ),
      },
      {
        width: 130,
        title: 'appointmentBook.isNewPatient',
        render: (record) => (
          <Typography>
            {record?.patientType && <FormattedMessage id={PATIENT_TYPE[record.patientType]?.label} />}
          </Typography>
        ),
      },
      {
        title: 'encounterDoctor',
        dataIndex: 'picName',
      },
      {
        title: 'checkUpType',
        dataIndex: 'serviceName',
      },
      {
        title: 'status',
        render: (record) => {
          const status = DENTAL_EXAM_STATUS[record?.status];
          return (
            <Typography
              noWrap
              sx={{
                color: status?.sx || 'primary.main',
                fontWeight: '500',
              }}
            >
              <FormattedMessage id={status?.label || ' '} />
            </Typography>
          );
        },
      },

      {
        title: 'checkupRoom',
        dataIndex: 'physicsRoomName',
      },
      {
        title: 'action',
        align: 'right',
        render: (record) => {
          const showEditPICButton =
            record.status !== 'FINISHED' &&
            record.status !== 'CANCELLED' &&
            currentUserId === record.picUserId &&
            (!record?.endTime ||
              (record.endTime && moment(record.endTime, BE_DATE_TIME_FORMAT).isAfter(moment().add(-1, 'day'))));
          const onOpenEditPICDialog = useEditPICSelectors.use.onOpenDialog();

          return (
            showEditPICButton && (
              <IconButtonTitle
                title="encounterList.examDoctor.action.picButton"
                onClick={(event) => {
                  event.stopPropagation();
                  onOpenEditPICDialog({
                    encounterId: record.id,
                    doctorName: record.picName,
                    doctorId: record.picUserId,
                    encounterType: 'DENTAL',
                  });
                }}
              >
                <DoctorExchange />
              </IconButtonTitle>
            )
          );
        },
      },
    ] as Columns[];
  }, [currentUserId]);

  return (
    <>
      <TableCustom
        dataSource={data || []}
        loading={loading}
        columns={columns}
        rowProps={(record: some) => ({
          selected: confirmDialog.open,
          onClick: async () => {
            if (record?.status === 'ARRIVED') {
              if (record?.picUserId !== appState.authen.user?.user_id) {
                setReason(
                  appState.authen.user?.role.indexOf('NURSE')
                    ? 'encounterDetail.wrongAssignment'
                    : 'encounterDetail.nurse.wrongAssignment',
                );
                setOpen(true);
                return;
              }
              if (
                record.createdTime &&
                moment(record.createdTime, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) !==
                  moment().format(FE_DATE_FORMAT)
              ) {
                setReason('encounterDetail.outTime');
                setOpen(true);
                return;
              }
              const confirm = await confirmDialog.promptConfirmation({
                okId: intl.formatMessage({ id: 'encounterDetail.confirmReceiveEncounterMessage' }),
                title: intl.formatMessage({ id: 'confirm' }),
                content: intl.formatMessage({ id: 'encounterDetail.confirmReceiveEncounter' }),
              });
              if (confirm) {
                onAcceptDentalExam(record?.id);
                confirmDialog.close();
              } else {
                confirmDialog.close();
                return;
              }
            }
            if (record?.status !== 'CANCELLED') {
              history.push(ROUTES.dentalDetail.gen(record?.id, record?.patient?.id));
            }
          },
          style: { cursor: 'pointer' },
        })}
        paginationProps={{
          count: total,
          page: pagination.page,
          rowsPerPage: pagination.pageSize,
          onPageChange: onPageChange,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
      />
      <DialogCustom
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        PaperProps={{
          style: {
            minWidth: 420,
          },
        }}
        title={'encounterDetail.confirmReceiveEncounterMessage'}
      >
        <DialogContent style={{ padding: 16 }}>
          <Typography variant="body1" component="div">
            <FormattedMessage id={reason} />
          </Typography>
        </DialogContent>
        <DialogActions style={{ padding: 16 }}>
          <Button variant="contained" color="primary" onClick={() => setOpen(false)} style={{ minWidth: 100 }}>
            <FormattedMessage id={'ok'} />
          </Button>
        </DialogActions>
      </DialogCustom>

      <EditPICDialog refreshData={refresh} />
    </>
  );
};

export default DentalExamList;
