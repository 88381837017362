import Search from '@mui/icons-material/Search';
import {
  Box,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { API_SERVER } from 'modules/common/api';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import {
  BE_DATE_FORMAT,
  DENTAL_DIAGNOSIS_BASIC_TYPE,
  ENCOUNTER_STATUS,
  FE_DATE_FORMAT,
  PATIENT_ADDRESS_TYPE,
  PAYMENT_PLAN,
  SERVICE_CATEGORY_LIST,
  some,
  SYSTEM_CATEGORY_SCOPE,
} from 'modules/common/constants';
import { VN_CODE } from 'modules/common/redux/commonReducer';
import { EMAIL_REGEX } from 'modules/common/regex';
import {
  FieldsType,
  ILayoutFields,
  ISchemaFields,
  ISchemaForm,
  IUiFields,
  SchemaType,
} from 'modules/common/SchemaForm/utils';
import { ENTER_REASON_OPTIONS, GENDER_OPTIONS } from 'modules/reception/constants';
import { formatPatientFormData } from 'modules/reception/component/utils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { NumericFormatText } from 'modules/common/utils';
import { axiosThunk } from '../../../../common/redux/axios';
import Stack from '@mui/material/Stack';

const AutocompleteOptionField: React.FC<{ name: string; value: string }> = ({ name, value }) => {
  return (
    <>
      <Box component="span" color="text.secondary" fontWeight="normal">
        {name}:&nbsp;
      </Box>
      <Box component="span" fontWeight={500}>
        {value}
      </Box>
    </>
  );
};

export const fieldSchema: ISchemaFields = ({ valuesField, methods, formProps }) => {
  const { intl, dispatch, appState, mode, disabledHealthcareServiceIds, physicsRoomOptions, picOptions } =
    formProps || {};

  const { setValue, reset, trigger, getValues } = methods;
  const patientResourceId = valuesField?.patientResourceId && mode !== 'edit';
  const requireMoreInfo = valuesField?.requireMoreInfo;

  const allServiceByTypeOptions = appState.reception.allServiceCategories;

  let listAllService: some[] = [];
  allServiceByTypeOptions
    ?.filter((option) => option.serviceType === SYSTEM_CATEGORY_SCOPE.healthCheck)
    ?.forEach((serviceType) => {
      serviceType?.categoryList?.forEach((group) => {
        if (group.items?.length > 0) {
          listAllService = listAllService.concat(
            group.items?.map((service) => ({
              ...service,
              groupName: group.name,
              groupId: group.id,
              priceListName: group.priceListName,
              priceListId: group.priceListId,
              serviceType: group.serviceType,
              serviceTypeLabel: serviceType.label,
              serviceTypeName: serviceType.name,
            })),
          );
        }
      });
    });

  let listAllPriceList: some[] = [];
  listAllService.forEach((service) => {
    if (listAllPriceList.findIndex((p) => p.id === service?.priceListId) < 0) {
      listAllPriceList = listAllPriceList.concat({
        id: service.priceListId,
        name: service.priceListName,
        serviceType: service.serviceType,
        serviceTypeLabel: service.serviceTypeLabel,
        serviceTypeName: service.serviceTypeName,
      });
    }
  });

  // list dich vu theo bang gia
  const serviceItemOptions = () => {
    return valuesField?.priceType
      ? listAllService.filter((v) => v.priceListId === valuesField?.priceType?.id) || []
      : listAllService;
  };

  const paymentCategoryOptions = Object.values(PAYMENT_PLAN);

  return {
    resourceId: {
      type: 'hidden',
    },
    id: {
      type: 'hidden',
    },
    search: {
      type: 'auto-complete',
      autoFocus: true,
      placeholder: intl.formatMessage({ id: 'therapy.label.register.searchForName' }),
      loadOptions: async (name: string) => {
        const json = await dispatch(axiosThunk({ url: API_SERVER.patient.searchPatient({ name }).url }));
        const options = json?.data?.content || [];
        if (options.length === 0 && !!patientResourceId && name?.trim()) {
          reset();
          setValue('name', name);
          setValue('search', { name });
        }
        return options;
      },
      defaultValue: null,
      onChange: async (value) => {
        if (value) {
          const dataDental = await dispatch(
            axiosThunk({
              url: API_SERVER.dentalDiagnosisBasic.getList({
                type: DENTAL_DIAGNOSIS_BASIC_TYPE.DENTAL.value,
                patientId: value?.id,
              }).url,
            }),
          );
          const dataMedical = await dispatch(
            axiosThunk({
              url: API_SERVER.dentalDiagnosisBasic.getList({
                type: DENTAL_DIAGNOSIS_BASIC_TYPE.MEDICAL_HISTORY.value,
                patientId: value?.id,
              }).url,
            }),
          );
          reset({ ...valuesField, ...formatPatientFormData(value, appState) });
          setValue('medicalHistoryList', dataMedical.data);
          setValue('dentalHistoryList', dataDental.data);
          trigger();
        } else {
          reset();
        }
      },
      onChangeInput: (e) => {
        setValue('name', e.currentTarget.value, { shouldValidate: true });
      },
      startAdornment: (
        <InputAdornment position="start" style={{ marginLeft: '12px', marginRight: '-4px' }}>
          <Search />
        </InputAdornment>
      ),
      isOptionEqualToValue: (selected, value) =>
        selected && value && selected.patientResourceId === value.patientResourceId,
      // getOptionLabel: (option) => option.name || ' ',
      renderOption: (props, option, state) => {
        const homeAddress = option?.patientAddressList?.find((address) => address?.type === PATIENT_ADDRESS_TYPE.home);
        const birthday = moment(option?.dob, BE_DATE_FORMAT);
        return (
          <li {...props} key={option.patientResourceId}>
            <Box fontSize="body2.fontSize" fontWeight={500}>
              <Box>
                <Box component="span">{option.name}</Box>
                {' - '}
                <Box component="span">
                  <FormattedMessage
                    id={
                      GENDER_OPTIONS.find((gender) => gender?.value?.toLowerCase() === option?.gender?.toLowerCase())
                        ?.value || 'gender.empty'
                    }
                  />
                </Box>
                {' - '}
                <Box component="span">{birthday.isValid() ? birthday.format(FE_DATE_FORMAT) : ''}</Box>
                {' - '}
                <AutocompleteOptionField name={intl.formatMessage({ id: 'idShort' })} value={option.identifierCode} />
                {' - '}
                <AutocompleteOptionField
                  name={intl.formatMessage({ id: 'telephoneShort' })}
                  value={option.mobilePhone}
                />
              </Box>
              <Box>
                <AutocompleteOptionField name={intl.formatMessage({ id: 'address' })} value={homeAddress?.address} />
              </Box>
            </Box>
          </li>
        );
      },
      propsWrapper: { xs: 6 },
    },
    searchByTel: {
      type: 'auto-complete',
      autoFocus: true,
      placeholder: intl.formatMessage({ id: 'therapy.label.register.searchForTel' }),
      loadOptions: async (telephone: string) => {
        const json = await dispatch(
          axiosThunk({ url: API_SERVER.patient.searchPatient({ phoneNumber: telephone }).url }),
        );
        const options = json?.data?.content || [];
        if (options.length === 0 && !!patientResourceId && telephone?.trim()) {
          reset();
          setValue('telephone', telephone);
          setValue('searchByTel', { telephone });
        }
        return options;
      },
      defaultValue: null,
      onChange: async (value) => {
        if (value) {
          const dataDental = await dispatch(
            axiosThunk({
              url: API_SERVER.dentalDiagnosisBasic.getListByPatient({
                type: DENTAL_DIAGNOSIS_BASIC_TYPE.DENTAL.value,
                patientId: value?.id,
              }),
            }),
          );
          const dataMedical = await dispatch(
            axiosThunk({
              url: API_SERVER.dentalDiagnosisBasic.getListByPatient({
                type: DENTAL_DIAGNOSIS_BASIC_TYPE.MEDICAL_HISTORY.value,
                patientId: value?.id,
              }),
            }),
          );
          reset({ ...valuesField, ...formatPatientFormData(value, appState) });
          setValue('medicalHistoryList', dataMedical.data);
          setValue('dentalHistoryList', dataDental.data);
          trigger();
        } else {
          reset();
        }
      },
      onChangeInput: (e) => {
        setValue('telephone', e.currentTarget.value, { shouldValidate: true });
      },
      startAdornment: (
        <InputAdornment position="start" style={{ marginLeft: '12px', marginRight: '-4px' }}>
          <Search />
        </InputAdornment>
      ),
      isOptionEqualToValue: (selected, value) =>
        selected && value && selected.patientResourceId === value.patientResourceId,
      getOptionLabel: (option) => option.telephone || ' ',
      renderOption: (props, option, state) => {
        const homeAddress = option?.patientAddressList?.find((address) => address?.type === PATIENT_ADDRESS_TYPE.home);
        const birthday = moment(option?.dob, BE_DATE_FORMAT);
        return (
          <li {...props} key={option.patientResourceId}>
            <Box fontSize="body2.fontSize" fontWeight={500}>
              <Box>
                <Box component="span">{option.name}</Box>
                {' - '}
                <Box component="span">
                  <FormattedMessage
                    id={
                      GENDER_OPTIONS.find((gender) => gender?.value?.toLowerCase() === option?.gender?.toLowerCase())
                        ?.value || 'gender.empty'
                    }
                  />
                </Box>
                {' - '}
                <Box component="span">{birthday.isValid() ? birthday.format(FE_DATE_FORMAT) : ''}</Box>
                {' - '}
                <AutocompleteOptionField name={intl.formatMessage({ id: 'idShort' })} value={option.identifierCode} />
                {' - '}
                <AutocompleteOptionField
                  name={intl.formatMessage({ id: 'telephoneShort' })}
                  value={option.mobilePhone}
                />
              </Box>
              <Box>
                <AutocompleteOptionField name={intl.formatMessage({ id: 'address' })} value={homeAddress?.address} />
              </Box>
            </Box>
          </li>
        );
      },
      propsWrapper: { xs: 6 },
    },
    patientCode: {
      type: 'hidden',
    },
    name: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'sale.customerEnter' }),
      label: intl.formatMessage({ id: 'sellingProduct.title.customerName' }),
      propsWrapper: { xs: 4 },
      register: {
        required: true,
      },
      readOnly: mode === 'register',
    },
    dob: {
      type: 'datePicker',
      placeholder: intl.formatMessage({ id: 'birthdayForm' }),
      label: intl.formatMessage({ id: 'therapy.birthdayAndAge' }),
      propsWrapper: { xs: 2.5 },
      disableFuture: true,
      openTo: 'year',
      register: {
        validate: (value) => {
          return !value ||
            (moment(value, BE_DATE_FORMAT, true).isValid() &&
              moment(value, BE_DATE_FORMAT, true).isBefore(moment().endOf('day')))
            ? true
            : intl.formatMessage({ id: 'validation.invalidDate' });
        },
        required: {
          value: !valuesField.age,
          message: intl.formatMessage({ id: 'encounter.registration.label.birthday.required' }),
        },
      },
      readOnly: (mode === 'register' && !requireMoreInfo) || valuesField.age,
      tooltipError: true,
    },
    age: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'enterAge' }),
      label: '',
      propsWrapper: { xs: 1.5 },
      readOnly: mode === 'register' || valuesField.dob,
      inputType: 'number',
      required: !valuesField.dob,
      register: {
        required: {
          value: !valuesField.dob,
          message: intl.formatMessage({ id: 'encounter.registration.label.birthday.required' }),
        },
      },
      sx: {
        '& .MuiInputLabel-asterisk': {
          visibility: 'hidden',
        },
      },
      tooltipError: true,
      className: 'label-age-encounter',
    },
    gender: {
      type: 'select',
      placeholder: intl.formatMessage({ id: 'pickGender' }),
      label: intl.formatMessage({ id: 'gender' }),
      propsWrapper: { xs: 4 },
      options: GENDER_OPTIONS,
      readOnly: mode === 'register' && !requireMoreInfo,
      register: {
        required: true,
      },
    },
    job: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'job' }),
      placeholder: intl.formatMessage({ id: 'jobEnter' }),
      propsWrapper: { xs: 4 },
      readOnly: mode === 'register' && !requireMoreInfo,
    },
    identifierCode: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'enterIdentification' }),
      label: intl.formatMessage({ id: 'identification' }),
      propsWrapper: { xs: 6 },
      defaultValue: '',
      readOnly: mode === 'register' && !requireMoreInfo,
      inputType: 'number',
    },
    mobilePhone: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'telephoneEnter' }),
      label: intl.formatMessage({ id: 'telephone' }),
      propsWrapper: { xs: 4 },
      register: {
        required: true,
      },
      inputType: 'number',
      readOnly: mode === 'register',
    },
    email: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'emailAddress' }),
      placeholder: intl.formatMessage({ id: 'enterEmail' }),
      propsWrapper: { xs: 6 },
      required: false,
      register: {
        pattern: {
          value: EMAIL_REGEX,
          message: intl.formatMessage({ id: 'emailValid' }),
        },
      },
      readOnly: mode === 'register' && !requireMoreInfo,
    },
    jobAddress: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'jobAddressEnter' }),
      label: intl.formatMessage({ id: 'jobAddress' }),
      propsWrapper: { xs: 4 },
      readOnly: mode === 'register' && !requireMoreInfo,
    },
    ethnic: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'ethnic' }),
      placeholder: intl.formatMessage({ id: 'ethnicEnter' }),
      propsWrapper: { xs: 4 },
      options: appState.common.ethnicList,
      readOnly: mode === 'register' && !requireMoreInfo,
      onChange: (value) => {
        if (value === '55') {
          setValue('nation', null);
        }
      },
    },
    nationality: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'nation' }),
      placeholder: intl.formatMessage({ id: 'nationEnter' }),
      propsWrapper: { xs: 4 },
      options: appState.common.countryList.filter((country) => {
        return valuesField.ethnic?.value === '55' ? country.value !== VN_CODE : true;
      }),
      defaultValue: appState.common.countryList?.find((v) => v.value === VN_CODE),
      readOnly: mode === 'register' && !requireMoreInfo,
    },
    province: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'province' }),
      placeholder: intl.formatMessage({ id: 'provinceEnter' }),
      propsWrapper: { xs: 4 },
      options: appState.common.provinceData,
      onChange: () => {
        setValue('district', null);
      },
      readOnly: mode === 'register' && !requireMoreInfo,
      hotKeys: 'alt+t',
    },
    district: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'district' }),
      placeholder: intl.formatMessage({ id: 'districtEnter' }),
      propsWrapper: { xs: 4 },
      options: valuesField.province?.districts,
      readOnly: mode === 'register' && !requireMoreInfo,
      hotKeys: 'alt+q',
    },
    address: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'addressEnter' }),
      label: intl.formatMessage({ id: 'address' }),
      propsWrapper: { xs: 8 },
      readOnly: mode === 'register' && !requireMoreInfo,
      hotKeys: 'alt+a',
    },
    // Contact info
    contactName: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'contactNameEnter' }),
      label: intl.formatMessage({ id: 'contactName' }),
      propsWrapper: { xs: 4 },
      readOnly: mode === 'register' && !requireMoreInfo,
    },

    contactTelephone: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'contactTelephoneEnter' }),
      label: intl.formatMessage({ id: 'contactTelephone' }),
      propsWrapper: { xs: 4 },
      readOnly: mode === 'register' && !requireMoreInfo,
    },
    contactAddress: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'contactAddressEnter' }),
      label: intl.formatMessage({ id: 'contactAddress' }),
      propsWrapper: { xs: 4 },
      readOnly: mode === 'register' && !requireMoreInfo,
    },
    // Step 2
    enterReason: {
      type: 'auto-complete',
      placeholder: intl.formatMessage({ id: 'enterReasonPick' }),
      label: intl.formatMessage({ id: 'enterReason' }),
      propsWrapper: { xs: 6 },
      options: ENTER_REASON_OPTIONS,
      disableClearable: true,
      isOptionEqualToValue: (selected, value) => selected && value && selected.id === value.id,
      getOptionLabel: (option) => intl.formatMessage({ id: option.labelId }) || ' ',
      onChange: () => {
        setValue('priceType', null);
        setValue('healthcareServiceItem', null);
      },
      readOnly: mode === 'view',
      register: {
        required: true,
      },
    },
    priceType: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'encounterList.groupPriceType' }),
      placeholder: intl.formatMessage({ id: 'encounterList.groupPriceTypeEnter' }),
      propsWrapper: { xs: 4 },
      options: listAllPriceList,
      onChange: () => {
        setValue('healthcareServiceItem', null);
      },
      isOptionEqualToValue: (selected, value) => selected && value && selected.id === value.id,
      getOptionLabel: (option) => option.name || ' ',
      // groupBy: (option) => option.serviceTypeName,
      readOnly: mode === 'edit',
    },
    healthcareServiceItem: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'dental.register.label.serviceName' }),
      placeholder: intl.formatMessage({ id: 'dental.registration.encounterInfo.serviceEnter' }),
      options: serviceItemOptions,
      isOptionEqualToValue: (selected, value) => selected && value && selected.id === value.id,
      getOptionLabel: (option) => option.name || ' ',
      getOptionDisabled: (option) => disabledHealthcareServiceIds?.has(option.id),
      propsWrapper: { xs: 4 },
      register: {
        required: true,
      },
      readOnly: mode === 'edit',
      groupBy: (option) => option.groupName,
      onChange: (option) => {
        setValue('sessionAmount', option?.detailList?.length || 0);
        setValue('packagePrice', option?.packagePrice || 0);
        setValue('totalPrice', option?.totalPrice || 0);
      },
    },
    reason: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'reason' }),
      placeholder: intl.formatMessage({ id: 'encounterList.reasonEnter' }),
      propsWrapper: { xs: 4 },
      readOnly: valuesField?.encounterStatus === ENCOUNTER_STATUS.finished,
    },
    sessionAmount: {
      type: 'label',
      label: intl.formatMessage({ id: 'therapy.create.dialog.label.therapyAmountFull' }),
      value: valuesField?.sessionAmount || 0,
      propsWrapper: { xs: 1, sx: { '& p': { paddingLeft: '17px' } } },
    },
    doctor: {
      type: 'select',
      label: intl.formatMessage({ id: 'calendar.label.personInCharge' }),
      placeholder: intl.formatMessage({ id: 'therapyList.filter.label.selectPicName' }),
      register: {
        required: true,
      },
      propsWrapper: { xs: 4 },
      options: picOptions,
      readOnly: valuesField?.encounterStatus === ENCOUNTER_STATUS.finished,
      rawOptions: true,
    },
    paymentCategory: {
      type: 'select',
      label: intl.formatMessage({ id: 'paymentCategory' }),
      placeholder: intl.formatMessage({ id: 'paymentCategoryPick' }),
      register: {
        required: true,
      },
      propsWrapper: { xs: 4 },
      readOnly: mode === 'edit' && valuesField.paymentCategory === PAYMENT_PLAN.FEE.value,
      defaultValue: PAYMENT_PLAN.FEE.value,
      options: paymentCategoryOptions,
    },
    physicsRoom: {
      type: 'select',
      label: intl.formatMessage({ id: 'dental.register.label.physicsRoom' }),
      placeholder: intl.formatMessage({ id: 'dental.register.placeholder.physicsRoom' }),
      propsWrapper: { xs: 4 },
      options: physicsRoomOptions,
      rawOptions: true,
      register: {
        required: false,
      },
      readOnly: mode === 'view',
    },
    dentalHistoryList: {
      type: () => {
        return (
          <>
            {valuesField?.dentalHistoryList?.map((v) => {
              return (
                <Chip
                  label={v?.name}
                  onDelete={() => {
                    const curr = [...getValues('dentalHistoryList')].filter((d) => d !== v);
                    setValue('dentalHistoryList', [...curr]);
                  }}
                  sx={{
                    marginRight: '3px',
                    marginBottom: '2px',
                  }}
                />
              );
            })}
          </>
        );
      },
      label: intl.formatMessage({ id: 'subclinical.parts' }),
      propsWrapper: {
        xs: 12,
        sx: {
          marginTop: '-20px',
        },
      },
      shouldUnregister: true,
    },
    dentalHistories: {
      type: 'auto-complete',
      propsWrapper: { xs: 4 },
      options: appState.common.dentalIllnessOptions,
      disableCloseOnSelect: true,
      getOptionLabel: (option) => option.value,
      isOptionEqualToValue: (opt, value) => opt.value === value.value,
      label: intl.formatMessage({ id: 'dental.register.label.dentalHistory' }),
      placeholder: intl.formatMessage({ id: 'dental.register.label.dentalHistory.select' }),
      onChange: (v) => {
        if (v) {
          if (getValues('dentalHistoryList')) {
            const currentList = [...getValues('dentalHistoryList')];
            setValue('dentalHistoryList', [...currentList, { name: v.value, value: v.value }]);
          } else {
            setValue('dentalHistoryList', [{ name: v.value, value: v.value }]);
          }
        }
      },
      renderOption: (props, option) => {
        return (
          <li {...props} key={option?.value}>
            <Box alignItems="center" display="flex">
              <Checkbox
                size="small"
                color="primary"
                style={{ marginRight: 8, padding: 0 }}
                checked={valuesField?.dentalHistoryList?.indexOf(option.value) > -1}
              />
              <Typography variant="body2" component="div">
                {option.value}
              </Typography>
            </Box>
          </li>
        );
      },
      getOptionDisabled: (option) => valuesField?.dentalHistoryList?.indexOf(option.value) > -1,
    },
    dentalHistoryText: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'dental.register.label.dentalHistory.enter' }),
      // label: '&nbsp;',
      propsWrapper: { xs: 4, sx: { paddingTop: '37px !important' } },
      // readOnly: mode === 'register',
      onKeyPress: (event) => {
        if (event?.key === 'Enter') {
          event.preventDefault();
          if (valuesField?.dentalHistoryText?.length > 0) {
            if (getValues('dentalHistoryList')?.length > 0) {
              setValue('dentalHistoryList', [
                ...getValues('dentalHistoryList'),
                { name: valuesField?.dentalHistoryText, value: valuesField?.dentalHistoryText },
              ]);
            } else {
              setValue('dentalHistoryList', [
                { name: valuesField?.dentalHistoryText, value: valuesField?.dentalHistoryText },
              ]);
            }
            setValue('dentalHistoryText', '');
          }
        }
      },
      onBlur: (event) => {
        event.preventDefault();
        if (valuesField?.dentalHistoryText?.length > 0) {
          if (getValues('dentalHistoryList')?.length > 0) {
            setValue('dentalHistoryList', [
              ...getValues('dentalHistoryList'),
              { name: valuesField?.dentalHistoryText, value: valuesField?.dentalHistoryText },
            ]);
          } else {
            setValue('dentalHistoryList', [
              { name: valuesField?.dentalHistoryText, value: valuesField?.dentalHistoryText },
            ]);
          }
          setValue('dentalHistoryText', '');
        }
      },
    },
    medicalHistoryList: {
      type: () => {
        return (
          <>
            {valuesField?.medicalHistoryList?.map((v) => {
              return (
                <Chip
                  label={v?.name}
                  onDelete={() => {
                    const curr = [...getValues('medicalHistoryList')].filter((d) => d !== v);
                    setValue('medicalHistoryList', [...curr]);
                  }}
                  sx={{
                    marginRight: '3px',
                    marginBottom: '2px',
                  }}
                />
              );
            })}
          </>
        );
      },
      label: intl.formatMessage({ id: 'subclinical.parts' }),
      propsWrapper: {
        xs: 12,
        sx: {
          marginTop: '-20px',
        },
      },
      shouldUnregister: true,
    },
    medicalHistories: {
      type: 'auto-complete',
      propsWrapper: { xs: 4 },
      options: appState.common.medicalIllnessOptions,
      disableCloseOnSelect: true,
      getOptionLabel: (option) => option.value,
      isOptionEqualToValue: (opt, value) => opt.value === value.value,
      label: intl.formatMessage({ id: 'dental.register.label.medicalHistory' }),
      placeholder: intl.formatMessage({ id: 'dental.register.label.medicalHistory.select' }),
      onChange: (v) => {
        if (v) {
          if (getValues('medicalHistoryList')) {
            const currentList = [...getValues('medicalHistoryList')];
            setValue('medicalHistoryList', [...currentList, { name: v.value, value: v.value }]);
          } else {
            setValue('medicalHistoryList', [{ name: v.value, value: v.value }]);
          }
        }
      },
      renderOption: (props, option) => {
        return (
          <li {...props} key={option?.value}>
            <Box alignItems="center" display="flex">
              <Checkbox
                size="small"
                color="primary"
                style={{ marginRight: 8, padding: 0 }}
                checked={valuesField?.medicalHistoryList?.indexOf(option.value) > -1}
              />
              <Typography variant="body2" component="div">
                {option.value}
              </Typography>
            </Box>
          </li>
        );
      },
      getOptionDisabled: (option) => valuesField?.medicalHistoryList?.indexOf(option.value) > -1,
    },
    medicalHistoryText: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'dental.register.label.medicalHistory.enter' }),
      propsWrapper: { xs: 4, sx: { paddingTop: '37px !important' } },
      // readOnly: mode === 'register',
      onKeyPress: (event) => {
        if (event?.key === 'Enter') {
          event.preventDefault();
          if (valuesField?.medicalHistoryText?.length > 0) {
            if (getValues('medicalHistoryList')?.length > 0) {
              setValue('medicalHistoryList', [
                ...getValues('medicalHistoryList'),
                { name: valuesField?.medicalHistoryText, value: valuesField?.medicalHistoryText },
              ]);
            } else {
              setValue('medicalHistoryList', [
                { name: valuesField?.medicalHistoryText, value: valuesField?.medicalHistoryText },
              ]);
            }

            setValue('medicalHistoryText', '');
          }
        }
      },
      onBlur: (event) => {
        event.preventDefault();
        if (valuesField?.medicalHistoryText?.length > 0) {
          if (getValues('medicalHistoryList')?.length > 0) {
            setValue('medicalHistoryList', [
              ...getValues('medicalHistoryList'),
              { name: valuesField?.medicalHistoryText, value: valuesField?.medicalHistoryText },
            ]);
          } else {
            setValue('medicalHistoryList', [
              { name: valuesField?.medicalHistoryText, value: valuesField?.medicalHistoryText },
            ]);
          }
          setValue('medicalHistoryText', '');
        }
      },
    },
  } as SchemaType;
};

const ui: IUiFields = ({ formProps, valuesField }) => {
  return [
    {
      id: 'search',
      fields: ['search', 'searchByTel'],
    },
    {
      id: 'patientInfo',
      title: (
        <Stack marginBottom={2} direction="row" justifyContent="space-between">
          <Typography variant="h6" style={{ marginRight: 24 }}>
            <FormattedMessage id="basicInfo" />
          </Typography>
          {valuesField?.code && (
            <Typography color="textSecondary">
              <FormattedMessage id="patientCodeShort" />
              :&nbsp;{valuesField?.code}
            </Typography>
          )}
        </Stack>
      ),
      fields: ['patientCode', 'name', 'mobilePhone', 'gender', 'dob', 'age', 'address'],
    },
    {
      id: 'moreInfo1',
      fields: ['email', 'identifierCode'],
    },
    {
      id: 'moreInfo2',
      fields: ['job', 'jobAddress', 'ethnic', 'nationality', 'province', 'district'],
    },
    {
      id: 'contactInfo',
      fields: ['contactName', 'contactTelephone', 'contactAddress'],
    },
    {
      id: 'encounterInfo',
      fields: [
        'priceType',
        'healthcareServiceItem',
        'reason',
        'doctor',
        'paymentCategory',
        'physicsRoom',
        'dentalHistories',
        'dentalHistoryText',
        'dentalHistoryList',
        'medicalHistories',
        'medicalHistoryText',
        'medicalHistoryList',
      ],
    },
    {
      id: 'footerCS',
      fields: ['refund'],
    },
  ] as FieldsType[];
};

const LayoutSchema: ILayoutFields = (props) => {
  const { listElement, valuesField, formProps, fields } = props;
  const { hiddenSearch, state, setState } = formProps;
  const encounterResourceId = props && props?.valuesField?.encounterResourceId;

  // handle change create consecutively
  const handleChangeCreateConsecutively = (e: React.ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem('create_onsecutively', e.target.checked.toString());
  };

  return (
    <>
      <Box padding={2}>
        {!hiddenSearch && listElement[0]}
        <Box marginBottom={2} display="flex" alignItems="baseline">
          <Typography variant="h6" style={{ marginRight: 24 }}>
            <FormattedMessage id="therapy.label.customer.info" />
          </Typography>
        </Box>
        <Box className="box-info">
          {listElement[1]}
          <Box marginTop={2}>
            <Box display="flex" alignItems="center" marginBottom={2}>
              <Typography variant="h6">
                <FormattedMessage id="moreInfo" />
              </Typography>
              <Divider style={{ flex: 1, marginLeft: 16 }} />
              <IconButtonTitle
                onClick={() => {
                  setState((old) => ({ ...old, moreInfoCollapse: !old.moreInfoCollapse }));
                }}
              >
                <KeyboardArrowDownIcon
                  style={{
                    transform: state.moreInfoCollapse ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'all 200ms',
                    fontSize: 24,
                  }}
                />
              </IconButtonTitle>
            </Box>
            <Collapse in={state.moreInfoCollapse}>
              <Box>{listElement[2]}</Box>
              <Box paddingTop={2}>{listElement[3]}</Box>
            </Collapse>
          </Box>
          <Box marginTop={2}>
            <Box display="flex" alignItems="center" marginBottom={2} className="contact-info-person">
              <Typography variant="h6">
                <FormattedMessage id="contactInfoPerson" />
              </Typography>
              <Divider style={{ flex: 1, marginLeft: 16 }} />
              <IconButtonTitle
                onClick={() => {
                  setState((old) => ({
                    ...old,
                    contactInfoPersonCollapse: !old.contactInfoPersonCollapse,
                  }));
                }}
              >
                <KeyboardArrowDownIcon
                  style={{
                    transform: state.contactInfoPersonCollapse ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'all 200ms',
                    fontSize: 24,
                  }}
                />
              </IconButtonTitle>
            </Box>
            <Collapse in={state.contactInfoPersonCollapse}>{listElement[4]}</Collapse>
          </Box>
        </Box>
        <Box marginTop={2}>
          <Box display="flex" alignItems="center" marginBottom={2}>
            <Typography variant="h6">
              <FormattedMessage id="encounterInfoUppercase" />
            </Typography>
          </Box>
          <Box className="box-info">
            <Box display="flex" alignItems="baseline">
              {listElement[5]}
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Grid container spacing={1} style={{ width: '35%', alignSelf: 'flex-end', marginTop: '10px' }}>
              <Grid item xs={6}>
                <Typography variant="h6" color="primary">
                  <FormattedMessage id="totalPrice" />
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'end' }}>
                <Typography variant="subtitle1">
                  {NumericFormatText(
                    (valuesField?.paymentCategory !== '2' && valuesField?.healthcareServiceItem?.price) || 0,
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box padding={2} paddingTop={0} display="flex" gap={2} justifyContent={'flex-end'}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            {!encounterResourceId && (
              <FormControlLabel
                control={<Checkbox onChange={handleChangeCreateConsecutively} />}
                label={<FormattedMessage id="encounter.register.label.createConsecutively" />}
              />
            )}
          </Grid>
          <Grid item xs={9} display={'flex'} justifyContent={'flex-end'}>
            <Box>{fields.footer.cancel}</Box>
            <Box marginLeft={'20px'}>{fields.footer.default}</Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export const receivePatientFormSchema: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
  layout: LayoutSchema,
  changeDataBeforeSubmit: (valuesField: some, formProps) => {
    const { price, insurancePay, originalPrice, reducedRate } = calculatePricing(valuesField);
    return { ...valuesField, price, insurancePay, originalPrice, reducedRate };
  },
};

export function calculatePricing(valuesField: some) {
  let price = 0;
  let originalPrice = 0;
  let insurancePay = 0;

  if (valuesField.healthcareServiceItem?.price) {
    price = valuesField.healthcareServiceItem.price;
    originalPrice = price;
  } else {
    // this means we are editing the encounter so we don't have
    // the price from the healthcareServiceItem
    price = valuesField.price;
    insurancePay = valuesField.insurancePay;
    originalPrice = valuesField.originalPrice;
    return { price, insurancePay, originalPrice };
  }

  const reducedRate = 0;
  if (valuesField.priceType?.code === SERVICE_CATEGORY_LIST.insured) {
    price = reducedRate * price;

    const copayRate = 1 - valuesField.insurePercent / 100;
    insurancePay = price - price * copayRate;
  }

  return { price, insurancePay, originalPrice, reducedRate };
}
