import { Add, CalendarMonth } from '@mui/icons-material';
import { Box, Button, ButtonGroup, Paper, Popover, Stack, Tab, Typography } from '@mui/material';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { DayViewIcon } from '../../../svg';
import LoadingIcon from '../../common/component/LoadingIcon';
import { BE_DATE_FORMAT, some } from '../../common/constants';
import useGeneralHook from '../../common/hook/useGeneralHook';
import usePaginationHook from '../../common/hook/usePaginationHook';
import EncounterCalendarBox from '../component/calendar/CalendarWeek';
import FilterCalendarDay from '../component/calendar/FilterCalendarDay';
import FormAppointmentDialog from '../component/calendar/FormAppointmentDialog';
import TableCalendarDay from '../component/calendar/TableCalendarDay';
import { BASIC_ROLE_INFO, ROLE_INFO } from '../../layout/utils';
import { AppointmentModel } from '@devexpress/dx-react-scheduler';
import { API_SERVER } from '../../common/api';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import DetailedReport from 'modules/admin/component/appointmentCalendar/DetailedReport';
import { Appointment, UserEmployeeRole } from 'modules/schema';
import { formatDateFilterField } from 'modules/common/utils';
import { Option } from 'modules/common/type';
import { useUserRoles } from 'modules/common/hook/useUserRoles';
import { useDeleteMutate } from 'modules/common/hook/useMutate';
import { APPOINTMENT_TYPES } from '../component/calendar/constants';
import { useFetch } from 'modules/common/hook';

const TYPE_CALENDAR = 'TYPE_CALENDAR';
export interface ActionControl {
  action: 'edit' | 'create';
  data?: some;
}

export type CalendarType = 'DAY' | 'WEEK';

const AppointmentCalendar = () => {
  const { intl } = useGeneralHook();
  const [selectedAppointment, setSelectedAppointment] = useState<Pick<AppointmentModel, 'id'> | null>(null);

  const hookPagination = usePaginationHook({
    defaultFilter: {
      date: moment().format(BE_DATE_FORMAT),
      dateRange: { start: moment().format(BE_DATE_FORMAT), end: moment().add(1, 'week').format(BE_DATE_FORMAT) },
    },
    disablePagination: true,
  });
  const { params, setParams } = hookPagination;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [formData, setFormData] = useState<Appointment | undefined>();
  const [type, setType] = useState<CalendarType>((localStorage.getItem(TYPE_CALENDAR) as CalendarType) || 'DAY');
  const doctorsAndNurse = useUserRoles('DOCTOR', 'GENERAL_DOCTOR', 'NURSE');
  const onCloseFormDialog = useCallback(() => {
    setFormData(undefined);
    setTypeDialog('');
  }, []);
  const [typeDialog, setTypeDialog] = useState<string>('');

  const dateRangeFormated: { fromDate: string; toDate: string } = useMemo(() => {
    if (type === 'DAY') {
      return formatDateFilterField({ fromDate: params.date, toDate: params.date }, 'fromDate', 'toDate');
    } else {
      return formatDateFilterField(
        { fromDate: params?.dateRange?.start, toDate: params?.dateRange.end },
        'fromDate',
        'toDate',
      );
    }
  }, [type, params?.date, params?.dateRange]);

  const doctorsAndNurseOptions = useMemo<(Option & UserEmployeeRole)[]>(
    () =>
      doctorsAndNurse
        ?.sort((a, b) => {
          if (a?.roleName === b?.roleName) {
            return a?.employeeFullName?.localeCompare(b?.employeeFullName!) || 0;
          }
          if (a?.roleName === ROLE_INFO.nurse.code) {
            return 1;
          }
          return -1;
        })
        .map((user) => {
          const roleKey =
            Object.keys(ROLE_INFO).find((key) => ROLE_INFO[key].roleKey === user?.roleName) ||
            Object.keys(BASIC_ROLE_INFO).find((key) => BASIC_ROLE_INFO[key].roleKey === user?.roleName) ||
            '';
          return {
            ...user,
            value: user?.id || '',
            label: `${user?.employeeFullName} - ${intl.formatMessage({
              id: ROLE_INFO?.[roleKey]?.label || BASIC_ROLE_INFO?.[roleKey]?.label || ' ',
            })}`,
          };
        }) || [],
    [doctorsAndNurse, intl],
  );

  const {
    data: appointments,
    revalidate: appointmentsRevalidate,
    isValidating,
  } = useFetch(
    API_SERVER.appointment.searchAll({
      ...dateRangeFormated,
      picUserIds: params?.picUserIds?.join(',') || null,
      mobilePhone: params?.mobilePhone,
      status: ['BOOKED', 'ARRIVED'],
    }),
  );

  const { data: therapyAppointments } = useFetch(
    API_SERVER.therapy.getTherapyAppointments({
      mobilePhone: params?.mobilePhone,
      practitionerResourceIdList: params?.picUserId?.join(',') || null,
      ...dateRangeFormated,
    }),
  );

  const mergedAppointment = useMemo<Appointment[]>(() => {
    return [
      ...(appointments || []),
      ...(therapyAppointments?.content || []).map((therapyAppointment) => ({
        id: therapyAppointment?.id,
        name: therapyAppointment?.name,
        mobilePhone: therapyAppointment?.mobilePhone,
        address: therapyAppointment?.address,
        startTime: therapyAppointment?.startTime,
        endTime: therapyAppointment?.endTime,
        picUserId: therapyAppointment?.picUserId,
        picName: therapyAppointment?.picName,
        reason: therapyAppointment?.description,
        quantity: 1,
        type: APPOINTMENT_TYPES.THERAPY.value,
      })),
    ];
  }, [appointments, therapyAppointments]);

  const deleteAppointmentMutate = useDeleteMutate({
    confirmMessage: 'confirmDeleteTitle',
    onSuccess: appointmentsRevalidate,
  });

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const revalidateAll = useCallback(() => {
    setAnchorEl(null);
    appointmentsRevalidate();
  }, [appointmentsRevalidate]);

  const handleChange = (_, newValue) => {
    setParams({ ...params, tab: newValue });
  };

  return (
    <>
      <Box display={'flex'} flexDirection="column" gap={2}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center' }}>
          <Typography variant="h6" flex={1}>
            <FormattedMessage id="calendar.list" />
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={() => {
              setFormData({});
              setTypeDialog('');
            }}
          >
            <FormattedMessage id="calendar.addSingle" />
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<Add />}
            onClick={() => {
              setFormData({});
              setTypeDialog('GROUP');
            }}
          >
            <FormattedMessage id="calendar.addGroup" />
          </Button>
        </Paper>
        <Paper sx={{ p: 2, flex: 1, gap: 1 }}>
          <TabContext value={params?.tab || 'appointment _schedule_chart'}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} variant="scrollable" indicatorColor="secondary" textColor="secondary">
                <Tab
                  label={
                    <Typography variant="subtitle2">
                      <FormattedMessage id="encounter.calendar.label.appointmentScheduleChart" />
                    </Typography>
                  }
                  value="appointment _schedule_chart"
                  key="appointment _schedule_chart"
                />
                <Tab
                  label={
                    <Typography variant="subtitle2">
                      <FormattedMessage id="encounter.calendar.label.appointmentScheduleBoard" />
                    </Typography>
                  }
                  value="appointment _schedule_board"
                  key="appointment _schedule_board"
                />
              </TabList>
            </Box>
            <TabPanel
              value="appointment _schedule_chart"
              key="appointment _schedule_chart"
              style={{
                padding: 0,
                overflow: 'hidden',
                display: 'block',
              }}
            >
              <Stack justifyContent="space-between" alignItems="center" direction="row" marginTop={1.5}>
                <FilterCalendarDay
                  hookPagination={hookPagination}
                  type={type}
                  doctorAndNurseOptions={doctorsAndNurseOptions}
                />
                <ButtonGroup variant="contained" color="primary" style={{ height: 'fit-content', marginTop: 8 }}>
                  <Button
                    variant={type === 'DAY' ? 'contained' : 'outlined'}
                    color="primary"
                    style={{ padding: 4 }}
                    onClick={() => {
                      setType('DAY');
                      localStorage.setItem(TYPE_CALENDAR, 'DAY');
                    }}
                  >
                    <DayViewIcon />
                  </Button>
                  <Button
                    variant={type === 'WEEK' ? 'contained' : 'outlined'}
                    color="primary"
                    style={{ padding: 4 }}
                    onClick={() => {
                      setType('WEEK');
                      localStorage.setItem(TYPE_CALENDAR, 'WEEK');
                    }}
                  >
                    <CalendarMonth />
                  </Button>
                </ButtonGroup>
              </Stack>
              {type === 'DAY' ? (
                <TableCalendarDay
                  picOtions={doctorsAndNurseOptions}
                  appointments={mergedAppointment}
                  onUpdate={(value) => {
                    setFormData(value);
                    if (value.appointmentGroupId) {
                      setTypeDialog('GROUP');
                    }
                  }}
                  onDelete={(value) => {
                    deleteAppointmentMutate({ url: API_SERVER.appointment.delete(value?.id!), method: 'delete' });
                  }}
                  setParams={setParams}
                  params={params}
                  selectedAppointment={selectedAppointment}
                  setSelectedAppointment={setSelectedAppointment}
                />
              ) : isValidating ? (
                <LoadingIcon />
              ) : (
                <EncounterCalendarBox
                  appointments={mergedAppointment}
                  onChange={() => {}}
                  onUpdate={(value) => {
                    setFormData(value);
                    if (value.appointmentGroupId) {
                      setTypeDialog('GROUP');
                    }
                  }}
                  onDelete={(value) => {
                    deleteAppointmentMutate({ url: API_SERVER.appointment.delete(value?.id!), method: 'delete' });
                  }}
                  params={params}
                />
              )}
            </TabPanel>
            <TabPanel
              value="appointment _schedule_board"
              key="appointment _schedule_board"
              style={{
                padding: 0,
                overflow: 'hidden',
                display: 'block',
              }}
            >
              <DetailedReport revalidateAppointment={appointmentsRevalidate} />
            </TabPanel>
          </TabContext>
        </Paper>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={() => {
            setAnchorEl(null);
          }}
          style={{ zIndex: 1300 }}
        ></Popover>
      </Box>
      <FormAppointmentDialog
        open={!!formData}
        formData={formData}
        close={onCloseFormDialog}
        revalidateAll={revalidateAll}
        examDoctorsNurses={doctorsAndNurseOptions}
        setSelectedAppointment={setSelectedAppointment}
        type={typeDialog}
      />
    </>
  );
};

export default AppointmentCalendar;
