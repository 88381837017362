import { Box, Dialog } from '@mui/material';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { API_SERVER } from 'modules/common/api';
import { DENTAL_DIAGNOSIS_BASIC_TYPE, ROLES, some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { setLoading } from 'modules/common/redux/commonReducer';
import SchemaForm from 'modules/common/SchemaForm';
import { ReceivePatientForm } from 'modules/reception/model';
import { loadAllServiceLists, loadTherapyServiceList } from 'modules/reception/redux/receptionReducer';
import {
  createPatientRequestBody,
  formatDentalExamFormData,
  formatPatientFormData,
} from 'modules/reception/component/utils';
import NewDentalExamWizardTitle from './NewDentalExamWizardTitle';
import { receivePatientFormSchema } from './schema';
import { axiosThunk } from 'modules/common/redux/axios';
import './style.scss';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';
import { useFetch, usePhysicRooms, useUserRoleOptions } from 'modules/common/hook';
import { PHYSICS_ROOM_CODE } from 'modules/common/apiConstants';

export interface ModeProps {
  type: string;
  patientId?: number;
  dentalExamId?: number;
}

interface Props {
  open: boolean;

  close(): void;

  refresh(): void;

  mode?: ModeProps | null;
  selectedObject: any;
}

const RegisterDentalForm: React.FunctionComponent<Props> = ({ close, refresh, mode, selectedObject }) => {
  const { dispatch, openSnackbar, intl, appState } = useGeneralHook();
  const userMap = useSelector((appState: AppState) => appState.common.users);
  const methodsForm = useForm<ReceivePatientForm>({
    reValidateMode: 'onChange',
    mode: 'onChange',
  });
  const physicsRooms = usePhysicRooms(PHYSICS_ROOM_CODE.DIAGNOSTICS_OR_THERAPEUTICS_UNIT.value);
  const physicsRoomOptions = React.useMemo(
    () => physicsRooms?.map((room) => ({ value: room.id, label: room.name })),
    [physicsRooms],
  );
  const createDentalDiagnosisBaseRequestBody = (data: some[], patientId, type) => {
    return data?.map((d) => ({ name: d?.name, type, patientId }));
  };

  const onSubmit = React.useCallback(
    async (data: some) => {
      dispatch(setLoading(true));
      try {
        let patientId;
        let patient;
        if (data.patientId) {
          //update patient
          const dataPatient = await dispatch(
            axiosThunk({
              url: API_SERVER.patient.updatePatient(data.patientId),
              method: 'put',
              data: createPatientRequestBody(data),
            }),
          );
          patientId = data.patientId;
          patient = dataPatient?.data;
        } else {
          const createPatientRes = await dispatch(
            axiosThunk({
              url: API_SERVER.patient.create(true),
              method: 'post',
              data: createPatientRequestBody(data),
            }),
          );
          patientId = createPatientRes?.data?.id;
          patient = createPatientRes?.data;
        }

        let dentalDiagnosisBasicVOList: some[] = [];
        if (data?.dentalHistoryList?.length > 0) {
          dentalDiagnosisBasicVOList = dentalDiagnosisBasicVOList.concat(
            createDentalDiagnosisBaseRequestBody(data?.dentalHistoryList, patientId, 'DENTAL'),
          );
        }
        if (data?.medicalHistoryList?.length > 0) {
          dentalDiagnosisBasicVOList = dentalDiagnosisBasicVOList.concat(
            createDentalDiagnosisBaseRequestBody(data?.medicalHistoryList, patientId, 'MEDICAL_HISTORY'),
          );
        }

        if (data.id) {
          //update dental session
          await dispatch(
            axiosThunk({
              url: API_SERVER.dentalExam.update(data.id),
              method: 'put',
              data: {
                patient: patient,
                patientId: patientId,
                note: data?.reason,
                reason: data?.reason,
                serviceId: data?.healthcareServiceItem?.id,
                paymentPlan: data?.paymentCategory,
                picUserId: userMap[data?.doctor]?.id,
                picName: userMap[data?.doctor]?.employeeFullName,
                registerReason: data?.reason,
                physicsRoomId: data?.physicsRoom,
                dentalDiagnosisBasicVOList: dentalDiagnosisBasicVOList,
              },
            }),
          );
          openSnackbar({
            message: intl.formatMessage({ id: 'receivePatient.editSucceeds' }),
            type: 'success',
          });
        } else {
          //create dental session
          await dispatch(
            axiosThunk({
              url: API_SERVER.dentalExam.create(),
              method: 'post',
              data: {
                patient: patient,
                note: data?.reason,
                reason: data?.reason,
                serviceId: data?.healthcareServiceItem?.id,
                paymentPlan: data?.paymentCategory,
                picUserId: userMap[data?.doctor]?.id,
                picName: userMap[data?.doctor]?.employeeFullName,
                registerReason: data?.reason,
                physicsRoomId: data?.physicsRoom,
                dentalDiagnosisBasicVOList: dentalDiagnosisBasicVOList,
              },
            }),
          );
          openSnackbar({
            message: intl.formatMessage({ id: 'receivePatient.createEncounterSucceeds' }),
            type: 'success',
          });
        }
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'receivePatient.createEncounterFails' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
        const isCreateConsecutively = localStorage.getItem('create_onsecutively');
        if (!isCreateConsecutively || isCreateConsecutively === 'false') {
          close();
        }
        if (isCreateConsecutively && isCreateConsecutively === 'true') {
          let patientInfo = {};
          let encounterInfo = {};
          if (mode?.type === 'register') {
            if (mode?.patientId) {
              const dataDental = await dispatch(
                axiosThunk({
                  url: API_SERVER.dentalDiagnosisBasic.getListByPatient({
                    type: DENTAL_DIAGNOSIS_BASIC_TYPE.DENTAL.value,
                    patientId: mode?.patientId,
                  }),
                }),
              );
              const dataMedical = await dispatch(
                axiosThunk({
                  url: API_SERVER.dentalDiagnosisBasic.getListByPatient({
                    type: DENTAL_DIAGNOSIS_BASIC_TYPE.MEDICAL_HISTORY.value,
                    patientId: mode?.patientId,
                  }),
                }),
              );
              const medicalHistory = dataMedical.data?.map((t) => t.name);
              const dentalHistory = dataDental.data?.map((t) => t.name);
              patientInfo = {
                ...(selectedObject?.patient
                  ? formatPatientFormData(selectedObject?.patient, appState)
                  : {
                      gender: 'female',
                      patientCode: '',
                      name: '',
                      categoryInsurance: 'uninsured',
                      telephone: '',
                      identification: '',
                      address: '',
                      birthday: '',
                    }),
                patientResourceId: mode?.patientId,
                requireMoreInfo: false,
                insurePercent: 0,
                medicalHistoryList: medicalHistory?.filter((value, index) => medicalHistory.indexOf(value) === index),
                dentalHistoryList: dentalHistory?.filter((value, index) => dentalHistory.indexOf(value) === index),
              };
            }
            return methodsForm.reset({ ...patientInfo, ...encounterInfo });
          }
          methodsForm.reset({
            patientCode: '',
            name: '',
            categoryInsurance: 'uninsured',
            telephone: '',
            identification: '',
            address: '',
            birthday: '',
          });
        }
        refresh();
      }
    },
    [dispatch, userMap, openSnackbar, intl, refresh, close, mode, methodsForm, selectedObject, appState],
  );

  React.useEffect(() => {
    dispatch(loadTherapyServiceList());
    dispatch(loadAllServiceLists(intl));
  }, [dispatch, intl]);

  const { data: dentalHistory } = useFetch(
    API_SERVER.dentalDiagnosisBasic.getList(
      mode?.type === 'edit'
        ? {
            type: DENTAL_DIAGNOSIS_BASIC_TYPE.DENTAL.value,
            dentalExamId: mode?.dentalExamId,
            patientId: mode?.patientId,
          }
        : {
            type: DENTAL_DIAGNOSIS_BASIC_TYPE.DENTAL.value,
            patientId: mode?.patientId,
          },
    ),
    { enabled: mode?.patientId },
  );

  const { data: medicalHistory } = useFetch(
    API_SERVER.dentalDiagnosisBasic.getList(
      mode?.type === 'edit'
        ? {
            type: DENTAL_DIAGNOSIS_BASIC_TYPE.MEDICAL_HISTORY.value,
            dentalExamId: mode?.dentalExamId,
            patientId: mode?.patientId,
          }
        : {
            type: DENTAL_DIAGNOSIS_BASIC_TYPE.MEDICAL_HISTORY.value,
            patientId: mode?.patientId,
          },
    ),
    { enabled: mode?.patientId },
  );

  React.useEffect(() => {
    if (selectedObject) {
      let patientInfo = {};
      let dentalExamInfo = {};
      if (mode?.dentalExamId) {
        dentalExamInfo = formatDentalExamFormData(selectedObject);
      }
      if (mode?.patientId) {
        patientInfo = {
          ...(selectedObject?.patient
            ? formatPatientFormData(selectedObject?.patient, appState)
            : {
                gender: 'female',
                patientCode: '',
                name: '',
                categoryInsurance: 'uninsured',
                telephone: '',
                identification: '',
                address: '',
                birthday: '',
              }),
          patientResourceId: mode?.patientId,
          requireMoreInfo: false,
          insurePercent: 0,
          medicalHistoryList: medicalHistory || [],
          dentalHistoryList: dentalHistory || [],
        };
      }

      methodsForm.reset({
        ...patientInfo,
        ...dentalExamInfo,
      });
    }
  }, [appState, dispatch, mode, methodsForm, selectedObject, medicalHistory, dentalHistory]);

  const picOptions = useUserRoleOptions(ROLES.DOCTOR.value, ROLES.GENERAL_DOCTOR.value);

  return (
    <>
      <NewDentalExamWizardTitle close={close} mode={mode?.type} />
      <Box overflow="auto" className="receive-wizard-dialog">
        <SchemaForm
          schema={receivePatientFormSchema}
          onSubmit={onSubmit}
          onCancel={() => close()}
          cancelLabel={<FormattedMessage id={'cancel'} />}
          submitLabel={<FormattedMessage id={mode?.type === 'edit' ? 'save' : 'encounterList.addEncounter'} />}
          hiddenSearch={!!mode?.patientId}
          methods={methodsForm}
          mode={mode?.type}
          physicsRoomOptions={physicsRoomOptions}
          picOptions={picOptions}
        />
      </Box>
    </>
  );
};

const RegisterDentalExamDialog: React.FunctionComponent<Props> = (props) => {
  const { open } = props;
  return (
    <Dialog
      open={open}
      maxWidth="md"
      keepMounted={false}
      PaperProps={{
        style: { minWidth: 680, position: 'relative', overflow: 'hidden' },
      }}
    >
      <RegisterDentalForm {...props} />
    </Dialog>
  );
};

export default RegisterDentalExamDialog;
