import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { some } from 'modules/common/constants';
import useSWR from 'swr';
import { API_SERVER } from 'modules/common/api';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import SchemaForm from 'modules/common/SchemaForm';
import { prescriptionBox } from './PrescriptionBox';
import { addExternalMedicineBox } from './AddExternalMedicineBox';
import { fetchThunk } from 'modules/common/redux/thunk';
import { setLoading } from 'modules/common/redux/commonReducer';
import { useParams } from 'react-router';
import { quicklyCreatePrescriptions } from './QuicklyCreatePrescriptions';
import './style.scss';
import HistoryPrescriptionDialog from './HistoryPrescriptionDialog';
import { filter, isEmpty, some as lodashSome } from 'lodash';
import { useDentalDetailStateSelector } from '../state';
import { MEDICATION_CATEGORY_SCOPE, PRESCRIPTION_TYPE, PRESCRIPTION_DETAIL_TYPE } from 'modules/common/apiConstants';
import PrintIcon from '@mui/icons-material/Print';
import { useFetch, useDialog } from 'modules/common/hook';
import PrescriptionPrintExamDoctorDialog from 'modules/examDoctor/component/detail/prescription/PrescriptionPrintExamDoctorDialog';

interface Props {}

const Prescription: React.FunctionComponent<Props> = (props) => {
  const [openEncounterForm, onOpenEncounterForm, onCloseEncounterForm] = useDialog();
  const { dispatch, intl, openSnackbar, appState, confirmDialog } = useGeneralHook();
  const { patientId, dentalSessionId } = useParams<{ patientId; dentalSessionId }>();
  const { promptConfirmation, close } = confirmDialog;
  const [openHistoryPescription, setOpenHistory] = useState(false);
  const readOnly = useDentalDetailStateSelector.use.readonly();
  // get data list medication categories
  const { data: medicationCategoryList = [] } = useFetch(
    API_SERVER.medicationCategory.getAllFetchCategory({
      scope: MEDICATION_CATEGORY_SCOPE.INVENTORY,
      isShowQuantityInventory: true,
    }),
  );

  // data list medication categories all
  const medicationOptionAll = useCallback(() => {
    const arr: any[] = [];
    let categoryList: any = [];
    categoryList = medicationCategoryList;
    categoryList.forEach((i) => {
      i?.medicationList?.forEach((i1: some) => {
        const obj = {
          ...i1,
          group: i.name,
        };
        arr.push(obj);
      });
    });
    return arr;
  }, [medicationCategoryList]);

  // get data list dental prescriptions
  const {
    data: dataDentalPrescriptions,
    // isValidating,
    mutate: dataDentalPrescriptionsMutate,
    revalidate: revalidateDentalPrescription,
  } = useSWR(
    API_SERVER.dental.getDentalPrescriptions({
      referenceId: dentalSessionId,
      type: PRESCRIPTION_TYPE.DENTAL_EXAM,
    }).url,
    async (url) => {
      return dispatch(fetchThunk(url, 'get'));
    },
    { revalidateOnMount: true, revalidateOnFocus: false },
  );

  // get data list dental prescriptions by patientId
  const { data: dataDentalPrescriptionsPatient } = useFetch(
    API_SERVER.dental.getDentalPrescriptionsPatient({
      referenceId: dentalSessionId,
      patientId,
      type: PRESCRIPTION_TYPE.DENTAL_EXAM,
    }),
    { revalidateOnMount: true, revalidateOnFocus: false },
  );

  // get data list dental prescriptions external
  const {
    data: dataDentalPrescriptionExternal,
    // isValidating,
    mutate: dataDentalPrescriptionExternalMutate,
    revalidate: revalidateDentalPrescriptionExternal,
  } = useSWR(
    API_SERVER.dental.getDentalPrescriptions({
      referenceId: dentalSessionId,
      type: PRESCRIPTION_TYPE.DENTAL_EXAM,
    }).url,
    async (url) => {
      return dispatch(fetchThunk(url, 'get'));
    },
    { revalidateOnMount: true, revalidateOnFocus: false },
  );

  // Get all Prescription Form Template
  const { data: dataPrescriptionTemplate } = useFetch(API_SERVER.dental.getDentalPrescriptionTemplate(), {
    revalidateOnMount: true,
    revalidateOnFocus: false,
  });

  const formData = {
    medicationList: dataDentalPrescriptions?.prescriptionDetailList?.filter(
      (i) => i?.type === PRESCRIPTION_DETAIL_TYPE.MEDICATION_INTERNAL,
    ),
    medicationListExternal: dataDentalPrescriptionExternal?.prescriptionDetailList?.filter(
      (i) => i?.type === PRESCRIPTION_DETAIL_TYPE.MEDICATION_EXTERNAL,
    ),
  };

  // select item medication
  const onSelectedMedication = useCallback(
    async (medication) => {
      const temp = [...dataDentalPrescriptions?.prescriptionDetailList, medication];
      await dataDentalPrescriptionsMutate({ ...dataDentalPrescriptions, medicationList: temp }, false);
    },
    [dataDentalPrescriptions, dataDentalPrescriptionsMutate],
  );

  // select item medication external
  const onSelectedMedicationExternal = useCallback(
    async (medication) => {
      const temp = [...dataDentalPrescriptionExternal?.prescriptionDetailList, medication];
      await dataDentalPrescriptionExternalMutate(
        { ...dataDentalPrescriptionExternal, medicationListExternal: temp },
        false,
      );
    },
    [dataDentalPrescriptionExternal, dataDentalPrescriptionExternalMutate],
  );

  // delete item medication
  const onDeleteMedication = useCallback(
    async (medication) => {
      const temp = [...dataDentalPrescriptions?.prescriptionDetailList];
      const index = temp.findIndex((e) => e.id === medication.id);
      temp.splice(index, 1);
      await dataDentalPrescriptionsMutate({ ...dataDentalPrescriptions, medicationList: temp }, false);
    },
    [dataDentalPrescriptions, dataDentalPrescriptionsMutate],
  );

  // delete item medication external
  const onDeleteMedicationExternal = useCallback(
    async (medication) => {
      const temp = [...dataDentalPrescriptionExternal?.prescriptionDetailList];
      const index = temp.findIndex((e) => e.id === medication.id);
      temp.splice(index, 1);
      await dataDentalPrescriptionExternalMutate(
        { ...dataDentalPrescriptionExternal, medicationListExternal: temp },
        false,
      );
    },
    [dataDentalPrescriptionExternal, dataDentalPrescriptionExternalMutate],
  );

  // update item medication
  const onUpdateMedication = useCallback(
    async (medication) => {
      const temp = [...dataDentalPrescriptions?.prescriptionDetailList];
      const index = temp.findIndex((e) => e.id === medication.id);
      temp[index] = medication;
      await dataDentalPrescriptionsMutate({ ...dataDentalPrescriptions, medicationList: temp }, false);
    },
    [dataDentalPrescriptions, dataDentalPrescriptionsMutate],
  );

  // map data medication list have intendedRoute
  const mappedDataMedicationList = React.useMemo(() => {
    let temp: any[] = [];
    formData?.medicationList?.forEach((i) => {
      const intendedRouteLabel = appState.common.intendedRoutes.find((v) => v.value === i.intendedRoute)?.label;
      const obj = {
        ...i,
        intendedRoute: intendedRouteLabel
          ? {
              value: i.intendedRoute,
              label: intendedRouteLabel,
            }
          : '',
      };
      temp.push(obj);
    });
    temp.sort((a, b) => a.id - b.id);
    return { medicationList: temp };
  }, [appState.common.intendedRoutes, formData?.medicationList]);

  // map data medication list have intendedRoute external
  const mappedDataMedicationListExternal = React.useMemo(() => {
    let temp: any[] = [];
    formData?.medicationListExternal?.forEach((i) => {
      const obj = {
        ...i,
      };
      temp.push(obj);
    });
    temp.sort((a, b) => a.id - b.id);
    return { medicationListExternal: temp };
  }, [formData?.medicationListExternal]);

  // create item medication
  const onSelectMedication = React.useCallback(
    async (medication: some) => {
      if (medication) {
        const medicationAdd = {
          patientId,
          dayNumber: '',
          intendedRoute: '',
          medicationId: medication?.id,
          medicationName: medication?.name,
          prescriptionType: PRESCRIPTION_TYPE.DENTAL_EXAM,
          quantity: 1,
          referenceId: dentalSessionId,
          type: PRESCRIPTION_DETAIL_TYPE.MEDICATION_INTERNAL,
          unit: medication?.numeratorUnit,
          unitPerDay: '',
          usage: '',
        };
        try {
          dispatch(setLoading(true));
          const res = await dispatch(
            fetchThunk(
              API_SERVER.dental.createDentalPrescriptionsDetail(),
              'post',
              medicationAdd,
              'application/json-patch+json',
            ),
          );
          onSelectedMedication(res);
          openSnackbar({ message: intl.formatMessage({ id: 'createSuccess' }) });
          revalidateDentalPrescription();
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'createFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
        }
      }
    },
    [patientId, dentalSessionId, dispatch, onSelectedMedication, openSnackbar, intl, revalidateDentalPrescription],
  );

  // create item medication external
  const onSelectMedicationExternal = React.useCallback(async () => {
    const medicationAdd = {
      patientId,
      dayNumber: '',
      intendedRoute: '',
      medicationName: '',
      prescriptionType: PRESCRIPTION_TYPE.DENTAL_EXAM,
      quantity: '',
      referenceId: dentalSessionId,
      type: PRESCRIPTION_DETAIL_TYPE.MEDICATION_EXTERNAL,
      unit: '',
      unitPerDay: '',
      usage: '',
    };
    try {
      dispatch(setLoading(true));
      const res = await dispatch(
        fetchThunk(
          API_SERVER.dental.createDentalPrescriptionsDetail(),
          'post',
          medicationAdd,
          'application/json-patch+json',
        ),
      );
      onSelectedMedicationExternal(res);
      openSnackbar({ message: intl.formatMessage({ id: 'createSuccess' }) });
      revalidateDentalPrescriptionExternal();
    } catch (e) {
      openSnackbar({ message: intl.formatMessage({ id: 'createFail' }), type: 'error' });
    } finally {
      dispatch(setLoading(false));
    }
  }, [
    patientId,
    dentalSessionId,
    dispatch,
    onSelectedMedicationExternal,
    openSnackbar,
    intl,
    revalidateDentalPrescriptionExternal,
  ]);

  // delete item medication
  const onDelete = React.useCallback(
    async (medication: some) => {
      try {
        dispatch(setLoading(true));
        await dispatch(fetchThunk(API_SERVER.dental.deleteDentalPrescriptionsDetail(medication?.id), 'delete'));
        onDeleteMedication(medication);
        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
        revalidateDentalPrescription();
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, intl, onDeleteMedication, openSnackbar, revalidateDentalPrescription],
  );

  // delete item medication external
  const onDeleteExternal = React.useCallback(
    async (medication: some) => {
      try {
        dispatch(setLoading(true));
        await dispatch(fetchThunk(API_SERVER.dental.deleteDentalPrescriptionsDetail(medication?.id), 'delete'));
        onDeleteMedicationExternal(medication);
        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
        revalidateDentalPrescriptionExternal();
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, intl, onDeleteMedicationExternal, openSnackbar, revalidateDentalPrescriptionExternal],
  );

  // update item medication
  const onChangeMedicationInfo = React.useCallback(
    async (medication: some) => {
      const medicationItem = medicationOptionAll().find((item) => item?.id === medication?.medication?.id);
      try {
        const medicationData: some = {
          ...medication,
          patientId,
          intendedRoute: medication?.intendedRoute?.value,
          medicationName: medication?.name,
          prescriptionType: PRESCRIPTION_TYPE.DENTAL_EXAM,
          referenceId: dentalSessionId,
          type: PRESCRIPTION_DETAIL_TYPE.MEDICATION_INTERNAL,
          unit: medicationItem?.numeratorUnit,
        };
        const res = await dispatch(
          fetchThunk(
            API_SERVER.dental.updateDentalPrescriptionsDetail(medicationData?.id),
            'put',
            medicationData,
            'application/json-patch+json',
          ),
        );
        const indentedRoute = {
          value: res.intendedRoute,
          label: appState.common.intendedRoutes.find((v) => v.value === res.intendedRoute)?.label,
        };
        onUpdateMedication({ ...res, indentedRoute: indentedRoute });
        // openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
        revalidateDentalPrescription();
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
      } finally {
        // dispatch(setLoading(false));
      }
    },
    [
      appState.common.intendedRoutes,
      dentalSessionId,
      dispatch,
      intl,
      medicationOptionAll,
      onUpdateMedication,
      openSnackbar,
      patientId,
      revalidateDentalPrescription,
    ],
  );

  // update item medication external
  const onChangeMedicationInfoExternal = React.useCallback(
    async (medication: some) => {
      try {
        const medicationData: some = {
          ...medication,
          patientId,
          prescriptionType: PRESCRIPTION_TYPE.DENTAL_EXAM,
          referenceId: dentalSessionId,
          type: PRESCRIPTION_DETAIL_TYPE.MEDICATION_EXTERNAL,
        };
        await dispatch(
          fetchThunk(
            API_SERVER.dental.updateDentalPrescriptionsDetail(medicationData?.id),
            'put',
            medicationData,
            'application/json-patch+json',
          ),
        );
        // onUpdateMedicationExternal(res);
        // openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
        // revalidateDentalPrescriptionExternal();
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
      } finally {
        // dispatch(setLoading(false));
      }
    },
    [patientId, dentalSessionId, dispatch, openSnackbar, intl],
  );

  // create dental prescriptions clone
  const prescriptionsClone = async (data, type) => {
    let prescriptionDetailVOList: some[] = [];
    const dataClone = filter(data.prescriptionDetailList, (item5) =>
      lodashSome(medicationOptionAll(), (arr) => lodashSome(arr, (item100) => item100.id === item5.medicationId)),
    );
    if (type === 'template') {
      data?.detailTemplateList?.forEach(async (i) => {
        const medicationItem = medicationOptionAll().find((item) => parseInt(item?.id) === i?.medicationId);
        prescriptionDetailVOList.push({
          patientId,
          dayNumber: '',
          intendedRoute: i?.intendedRoute,
          medicationId: i?.medicationId,
          medicationName: '',
          prescriptionType: PRESCRIPTION_TYPE.DENTAL_EXAM,
          quantity: i?.quantity,
          referenceId: dentalSessionId,
          type: PRESCRIPTION_DETAIL_TYPE.MEDICATION_INTERNAL,
          unit: medicationItem?.numeratorUnit,
          unitPerDay: '',
          usage: i?.usage,
        });
      });
    } else {
      dataClone?.forEach(async (i) => {
        prescriptionDetailVOList.push({
          patientId,
          dayNumber: '',
          intendedRoute: i?.intendedRoute,
          medicationId: i?.medicationId,
          medicationName: i?.medicationName,
          prescriptionType: PRESCRIPTION_TYPE.DENTAL_EXAM,
          quantity: i?.quantity,
          referenceId: dentalSessionId,
          type: i?.type,
          unit: i?.unit,
          unitPerDay: '',
          usage: i?.usage,
        });
      });
    }
    try {
      dispatch(setLoading(true));
      const res = await dispatch(
        fetchThunk(
          API_SERVER.dental.createDentalPrescriptionsClone(),
          'post',
          {
            patientId,
            advice: data?.advice,
            referenceId: dentalSessionId,
            type: PRESCRIPTION_TYPE.DENTAL_EXAM,
            prescriptionDetailVOList: prescriptionDetailVOList,
          },
          'application/json-patch+json',
        ),
      );
      onSelectedMedication(res);
      openSnackbar({ message: intl.formatMessage({ id: 'createSuccess' }) });
    } catch (e) {
      openSnackbar({ message: intl.formatMessage({ id: 'createFail' }), type: 'error' });
    } finally {
      dispatch(setLoading(false));
    }
    revalidateDentalPrescription();
  };

  // tao nhanh don thuoc tu mau don thuoc
  const addTemplate = async (data) => {
    if (!isEmpty(mappedDataMedicationList?.medicationList)) {
      const confirm = await promptConfirmation({
        title: intl.formatMessage({ id: 'encounterPrescription.confirmTile' }),
        content: intl.formatMessage({ id: 'encounterPrescription.confirmAddTemplate' }),
        okId: 'ok',
      });
      if (confirm) {
        prescriptionsClone(data, 'template');
      }
      close();
    } else {
      prescriptionsClone(data, 'template');
    }
  };

  // tao nhanh don thuoc tu don thuoc cu
  const addTemplateHistory = async (data) => {
    if (!isEmpty(mappedDataMedicationList?.medicationList)) {
      const confirm = await promptConfirmation({
        title: intl.formatMessage({ id: 'encounterPrescription.confirmTile' }),
        content: intl.formatMessage({ id: 'encounterPrescription.confirmAddTemplate' }),
        okId: 'ok',
      });
      if (confirm) {
        prescriptionsClone(data, 'previousPrescription');
      }
      close();
    } else {
      prescriptionsClone(data, 'previousPrescription');
    }
    setOpenHistory(false);
  };

  // loi dan cua bac si
  const doctorAdvice = async (value: string) => {
    const payload = {
      patientId,
      advice: value,
      referenceId: dentalSessionId,
      type: PRESCRIPTION_TYPE.DENTAL_EXAM,
    };
    try {
      dispatch(setLoading(true));
      await dispatch(
        fetchThunk(API_SERVER.dental.createDentalPrescriptions(), 'post', payload, 'application/json-patch+json'),
      );
      openSnackbar({ message: intl.formatMessage({ id: 'createSuccess' }) });
      revalidateDentalPrescription();
    } catch (e) {
      openSnackbar({ message: intl.formatMessage({ id: 'createFail' }), type: 'error' });
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <>
      <Box bgcolor="white" className="mb10">
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={2}>
            <Typography
              className="title"
              fontWeight={500}
              fontSize={'24px'}
              color={'#37474F'}
              padding={'15px 0 5px 10px'}
            >
              <FormattedMessage id="encounterPrescription.applyTemplate" />
            </Typography>
          </Grid>
          <Grid item xs={10} paddingTop={'10px'}>
            <Button
              startIcon={<PrintIcon />}
              variant="contained"
              color="inherit"
              style={{ width: 180 }}
              disabled={
                dataDentalPrescriptions?.prescriptionDetailList &&
                dataDentalPrescriptions?.prescriptionDetailList?.length === 0
              }
              onClick={() => {
                onOpenEncounterForm();
              }}
            >
              <FormattedMessage id="encounterPrescription.print" />
            </Button>
          </Grid>
        </Grid>
        <Box padding={1} className="dental-medication-prescription">
          <SchemaForm
            schema={quicklyCreatePrescriptions}
            formData={mappedDataMedicationList}
            medicationCategoryList={medicationCategoryList}
            onSelectMedication={onSelectMedication}
            onDelete={onDelete}
            onChangeMedicationInfo={onChangeMedicationInfo}
            formId="exam-price"
            formProps={{
              autoComplete: 'nope',
              autoCorrect: 'off',
            }}
            readOnly={readOnly}
            noHelperText={true}
            listTemplate={dataPrescriptionTemplate}
            addTemplate={addTemplate}
            setOpenHistory={setOpenHistory}
          />
        </Box>
        <Typography className="title" fontWeight={500} fontSize={'24px'} color={'#37474F'} padding={'15px 0 5px 10px'}>
          <FormattedMessage id="encounterPrescription.addSingleMedicationRequest" />
        </Typography>
        <Box padding={1} className="dental-medication-prescription">
          <SchemaForm
            schema={prescriptionBox}
            formData={mappedDataMedicationList}
            medicationCategoryList={medicationCategoryList}
            onSelectMedication={onSelectMedication}
            onDelete={onDelete}
            onChangeMedicationInfo={onChangeMedicationInfo}
            formId="exam-price"
            formProps={{
              autoComplete: 'nope',
              autoCorrect: 'off',
            }}
            readOnly={readOnly}
            noHelperText={true}
            openSnackbar={openSnackbar}
          />
        </Box>

        <Typography className="title" fontWeight={500} fontSize={'24px'} color={'#37474F'} padding={'10px 0 0 10px'}>
          <FormattedMessage id="dental.prescription.label.addExtraMedicine" />
        </Typography>
        <Typography className="title" fontStyle={'italic'} fontSize={'14px'} color={'#90A4AE'} paddingLeft={'10px'}>
          <FormattedMessage id="dental.prescription.text.notIncludedInClinicMedicineData" />
        </Typography>
        <Box padding={1} paddingTop={3}>
          <SchemaForm
            schema={addExternalMedicineBox}
            formData={mappedDataMedicationListExternal}
            medicationCategoryList={medicationCategoryList}
            onDeleteExternal={onDeleteExternal}
            onChangeMedicationInfoExternal={onChangeMedicationInfoExternal}
            formId="exam-price"
            formProps={{
              autoComplete: 'nope',
              autoCorrect: 'off',
            }}
            readOnly={readOnly}
            noHelperText={true}
          />
          <Typography
            onClick={() => {
              if (!readOnly) {
                onSelectMedicationExternal();
              }
            }}
            color={'#003CA6'}
            marginTop={'10px'}
            sx={{ cursor: 'pointer' }}
          >
            <FormattedMessage id="dental.prescription.label.addExtraMedicinePlus" />
          </Typography>
        </Box>

        <Typography className="title" fontWeight={500} padding={'10px 0 0 10px'}>
          <FormattedMessage id="encounterPrescription.note" />
        </Typography>
        <Box padding={'8px 8px 30px 8px'}>
          <TextField
            disabled={readOnly}
            id="outlined-multiline-static"
            placeholder={intl.formatMessage({ id: 'encounterPrescription.noteEnter' })}
            inputProps={{ maxLength: 255 }}
            multiline
            rows={6}
            defaultValue={dataDentalPrescriptions?.advice}
            fullWidth
            onBlur={(e) => {
              if (e.target.value?.trim()) {
                doctorAdvice(e.target.value?.trim());
              } else {
                doctorAdvice('');
              }
            }}
          />
        </Box>
      </Box>
      <HistoryPrescriptionDialog
        open={openHistoryPescription}
        onClose={() => {
          setOpenHistory(false);
        }}
        onSubmit={addTemplateHistory}
        dataDentalPrescriptionsPatient={dataDentalPrescriptionsPatient}
      />
      <PrescriptionPrintExamDoctorDialog
        open={openEncounterForm}
        encounter={{}}
        prescription={dataDentalPrescriptions}
        onClose={onCloseEncounterForm}
        presNote={dataDentalPrescriptionExternal?.advice}
      />
    </>
  );
};

export default Prescription;
