import Search from '@mui/icons-material/Search';
import { Box, InputAdornment } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { API_SERVER } from '../../../common/api';
import { BE_DATE_FORMAT } from '../../../common/constants';
import { NUMBER_REGEX_NO_ZERO_HEADER } from '../../../common/regex';
import { FieldsType, ISchemaFields, ISchemaForm, IUiFields, SchemaType } from '../../../common/SchemaForm/utils';
import ScheduleSetting from './ScheduleSetting';
import { axiosThunk } from 'modules/common/redux/axios';
import { z } from 'zod';
import { AppointmentGroupSchema, PatientSchema } from 'modules/schema';
import { APPOINTMENT_TYPES } from './constants';
import { useParams } from 'react-router';
import { GENDER } from 'modules/common/apiConstants';

const AutocompleteOptionField: React.FC<{ name: string; value: string }> = ({ name, value }) => {
  return (
    <>
      <Box component="span" color="text.secondary" fontWeight="normal">
        {name}:
      </Box>
      <Box component="span" fontWeight={500}>
        {value}
      </Box>
    </>
  );
};

export const FieldSchema: ISchemaFields = ({ valuesField, methods, formProps }) => {
  const { intl, dispatch, examDoctorsNurses, formData } = formProps || {};
  const isGroup = formData?.type === APPOINTMENT_TYPES.APPOINTMENT_GROUP.value || formData?.type === 'GROUP';
  const { setValue, reset, clearErrors } = methods;
  const { encounterId } = useParams<{ encounterId }>();
  return {
    search: {
      type: 'auto-complete',
      autoFocus: true,
      placeholder: intl.formatMessage({ id: 'telephoneEnter' }),
      loadOptions: async (mobilePhone: string) => {
        if (isGroup) {
          const { data } = await dispatch(
            axiosThunk({ url: API_SERVER.appointmentGroup.searchByMobilePhone({ mobilePhone }).url }),
          );
          const parseResult = z.array(AppointmentGroupSchema).safeParse(data);
          return parseResult.success
            ? parseResult.data.map((appointmentGroup) => ({
                value: appointmentGroup.id,
                label: appointmentGroup.name,
                ...appointmentGroup,
              }))
            : [];
        } else {
          const { data } = await dispatch(
            axiosThunk({ url: API_SERVER.patient.searchByMobilePhoneAndName({ mobilePhone }).url }),
          );
          const parseResult = z.array(PatientSchema).safeParse(data);
          return parseResult.success
            ? parseResult.data.map((patient) => ({ value: patient.id, label: patient.name, ...patient }))
            : [];
        }
      },
      onChange: (value) => {
        if (value) {
          setValue('referenceId', value.id);
          setValue('name', value.name);
          setValue('mobilePhone', value.mobilePhone);
          setValue('address', value?.homeAddress?.address || value?.address);
          setValue('gender', value?.gender);
          setValue('dob', value?.dob);
          clearErrors();
        } else {
          reset();
        }
      },
      startAdornment: (
        <InputAdornment position="start" style={{ marginLeft: '12px', marginRight: '-4px' }}>
          <Search />
        </InputAdornment>
      ),
      renderOption: (props, option, state) => (
        <li {...props} key={option?.id}>
          <Box fontSize="body2.fontSize" fontWeight={500}>
            <Box>
              <Box component="span">{option.name}</Box>
              {' - '}
              <AutocompleteOptionField name={intl.formatMessage({ id: 'telephoneShort' })} value={option.mobilePhone} />
            </Box>
          </Box>
        </li>
      ),
      noHelperText: true,
    },
    referenceId: {
      type: 'hidden',
    },
    name: {
      type: 'text-field',
      label: intl.formatMessage({ id: isGroup ? 'calendar.presentName' : 'calendar.patientName' }),
      placeholder: intl.formatMessage({ id: 'enter' }),
      required: true,
      register: {
        validate: (value) => (value?.trim() ? true : intl.formatMessage({ id: 'required' })),
      },
      propsWrapper: { xs: 8 },
      readOnly: valuesField?.referenceId || encounterId || valuesField.id,
    },
    gender: {
      type: 'select',
      placeholder: intl.formatMessage({ id: 'pickGender' }),
      label: intl.formatMessage({ id: 'gender' }),
      options: [GENDER.OTHER, GENDER.FEMALE, GENDER.MALE],
      propsWrapper: { xs: 4 },
      readOnly: valuesField?.referenceId || encounterId || valuesField.id,
    },
    mobilePhone: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'telephoneEnter' }),
      label: intl.formatMessage({ id: 'telephone' }),
      inputType: 'number',
      register: {
        validate: (value) => (value?.trim() ? true : intl.formatMessage({ id: 'required' })),
      },
      readOnly: valuesField?.referenceId || encounterId || valuesField.id,
      required: true,
      propsWrapper: { xs: 8 },
    },
    dob: {
      type: 'datePicker',
      label: intl.formatMessage({ id: 'birthdayShort' }),
      propsWrapper: { xs: 4 },
      disableFuture: true,
      readOnly: valuesField?.referenceId || encounterId || valuesField.id,
    },
    address: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'address' }),
      placeholder: intl.formatMessage({ id: 'addressEnter' }),
      readOnly: valuesField?.referenceId || encounterId || valuesField.id,
      register: {
        maxLength: {
          value: 1000,
          message: intl.formatMessage({ id: 'common.text.length.invalid' }),
        },
      },
    },
    quantity: {
      type: 'text-field',
      inputType: 'number',
      label: intl.formatMessage({ id: 'calendar.totalPatient' }),
      placeholder: intl.formatMessage({ id: 'enter' }),
      defaultValue: 2,
      register: {
        required: true,
        min: { value: 2, message: intl.formatMessage({ id: 'calendar.min' }) },
        pattern: {
          value: NUMBER_REGEX_NO_ZERO_HEADER,
          message: intl.formatMessage({ id: 'calendar.totalPatientInvalid' }),
        },
      },
      required: true,
    },
    date: {
      type: 'datePicker',
      label: intl.formatMessage({ id: 'encounterDate' }),
      disablePast: true,
      register: {
        required: true,
        validate: (value) =>
          value && moment(value, BE_DATE_FORMAT).isSameOrAfter(moment().startOf('day'))
            ? true
            : intl.formatMessage({ id: 'calendar.dateInvalid' }),
      },
    },
    startHour: {
      type: 'timePicker',
      label: intl.formatMessage({ id: 'calendar.checkinTime' }),
      disablePast: true,
      register: {
        required: true,
        validate: (value) =>
          valuesField.endHour && value && moment(valuesField.endHour, 'HH:mm').isAfter(moment(value, 'HH:mm'))
            ? true
            : intl.formatMessage({ id: 'calendar.timeInvalid' }),
      },
      propsWrapper: { xs: 6 },
    },
    endHour: {
      type: 'timePicker',
      placeholder: intl.formatMessage({ id: 'birthdayForm' }),
      label: intl.formatMessage({ id: 'calendar.checkoutTime' }),
      disablePast: true,
      register: {
        required: true,
      },
      propsWrapper: { xs: 6 },
    },
    picUserId: {
      type: 'select',
      label: intl.formatMessage({ id: 'calendar.doctor' }),
      placeholder: intl.formatMessage({ id: 'select' }),
      rawOptions: true,
      options: examDoctorsNurses,
    },
    reason: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'reason' }),
      multiline: true,
      rows: 4,
      noHelperText: true,
    },
    autoSchedule: {
      unregister: true,
      type: !valuesField?.id && !isGroup ? ScheduleSetting : null,
      propsWrapper: { sx: { padding: '0 !important' } },
    },
  } as SchemaType;
};

const ui: IUiFields = ({ formProps }) => {
  const { intl, disableSearch, formData } = formProps;
  const isGroup = formData?.type === 'GROUP' || formData?.appointmentGroupId;
  return [
    {
      id: 'search',
      hidden: disableSearch,
      fields: ['search'],
    },
    {
      id: 'patientInfo',
      title: intl.formatMessage({ id: isGroup ? 'calendar.groupInfo' : 'calendar.patientInfo' }),
      fields: isGroup
        ? ['name', 'gender', 'mobilePhone', 'dob', 'address', 'quantity']
        : ['name', 'gender', 'mobilePhone', 'dob', 'address'],
    },
    {
      id: 'encounterInfo',
      title: intl.formatMessage({ id: 'calendar.appointmentInfo' }),
      fields: ['date', 'startHour', 'endHour', 'message', 'picUserId', 'reason', 'autoSchedule'],
    },
  ] as FieldsType[];
};

export const schema: ISchemaForm = {
  fields: FieldSchema,
  ui: ui,
};
