import { DECIMAL_NUMBER_REGEX } from 'modules/common/regex';
import { ISchemaFields, ISchemaForm, IUiFields } from 'modules/common/SchemaForm/utils';
import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import { Delete } from '@mui/icons-material';
import { some } from 'modules/common/constants';
import { NumericFormatText } from 'modules/common/utils';
import { DEFAULT_INSTRUCTION_KEY } from 'modules/examDoctor/component/detail/prescription/constants';

export const fieldSchema: ISchemaFields = ({ valuesField, formProps, methods }) => {
  const { intl, medicationCategoryList, onSelectMedication, onDelete, onChangeMedicationInfo, readOnly, openSnackbar } =
    formProps || {};

  const { setValue } = methods;

  const getMedicationItemOptions = () => {
    const arr: any[] = [];
    let categoryList: any[] = [];
    categoryList = valuesField?.categoryOption
      ? medicationCategoryList.filter((v) => v.id === valuesField?.categoryOption?.id) || []
      : medicationCategoryList;
    categoryList?.forEach((i) => {
      i?.medicationList?.forEach((i1: some) => {
        const obj = {
          ...i1,
          group: i?.name,
        };
        arr.push(obj);
      });
    });
    return arr;
  };

  const getAllMedicationItemOptions = () => {
    const arr: any[] = [];
    let categoryList: any[] = [];
    categoryList = medicationCategoryList;
    categoryList?.forEach((i) => {
      i?.medicationList?.forEach((i1: some) => {
        const obj = {
          ...i1,
          group: i.name,
        };
        arr.push(obj);
      });
    });
    return arr;
  };

  const medicationOptions = getMedicationItemOptions();
  const medicationOptionAll = getAllMedicationItemOptions();

  const getIndentedRouteOptions = (optionId) => {
    const arr: any[] = [];
    const medicationId: any[] = [];
    medicationCategoryList?.forEach((i) => {
      const medication = i?.medicationList?.find((ele) => ele.id === optionId);
      if (medication) {
        medication?.intendedRoute?.forEach((ele) => {
          if (!medicationId?.includes(medication?.id)) {
            arr.push(ele);
          }
        });
        medicationId.push(medication?.id);
      }
    });
    return arr;
  };

  return {
    categoryOption: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'therapy.detail.label.drugGroup' }),
      placeholder: intl.formatMessage({ id: 'therapy.detail.label.drugGroupEnter' }),
      propsWrapper: { xs: 6 },
      options: medicationCategoryList,
      isOptionEqualToValue: (selected, value) => selected?.id === value?.id,
      getOptionLabel: (option) => option.name || ' ',
      onChange: () => {
        setValue('medicationOption', null);
      },
    },
    medicationOption: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'therapy.detail.label.drugName' }),
      placeholder: intl.formatMessage({ id: 'therapy.detail.label.drugNameEnter' }),
      options: medicationOptions,
      getOptionLabel: (option) => (option ? `${option.name} (${option.ingredient || ''} ${option.dose || ''})` : ' '),
      propsWrapper: { xs: 6 },
      groupBy: (option) => option.group,
      onChange: (value) => {
        if (value && value.id) {
          onSelectMedication(value);
        }
      },
      getOptionDisabled: (option) => {
        return !!valuesField?.medicationList?.find((ele) => ele.medicationId === option?.id);
      },
    },
    medicationList: {
      type: 'array',
      hid: true,
      disableCloseBtn: true,
      addable: false,
      paperItemProps: { style: { padding: 0 }, variant: 'elevation', elevation: 0 },
      gridItemProps: { sx: { paddingTop: '8px !important' } },

      schema: {
        fields: ({ formProps, valuesField }) => {
          const item = medicationOptionAll.find((i) => i.id === valuesField.medicationId);

          const { arrayData, methodsArray } = formProps;
          const medicationPrice =
            medicationOptionAll?.find((ele) => String(ele.id) === String(valuesField.medicationId))?.publicPrice ||
            item?.publicPrice;
          return {
            id: {
              type: 'hidden',
            },
            group: {
              type: () => {
                return (
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography>
                        <FormattedMessage id="therapy.detail.label.drugGroup" />
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="subtitle1">{item?.group}</Typography>
                    </Grid>
                  </Grid>
                );
              },
              propsWrapper: { xs: 4 },
            },
            name: {
              type: () => {
                return (
                  <div>
                    <Grid container>
                      <Grid item xs={2.4}>
                        <Typography>
                          <FormattedMessage id="therapy.detail.label.drugNameLabel" />
                        </Typography>
                      </Grid>
                      <Grid item xs={9.6}>
                        <Typography variant="subtitle1">{item?.name}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                );
              },
              propsWrapper: { xs: 5 },
            },
            inventory: {
              type: () => {
                const medication = getAllMedicationItemOptions().filter((e) => e.id === item?.id);
                return (
                  <div>
                    <Grid container>
                      <Grid item xs={3.5}>
                        <Typography color={'#003CA6'}>
                          <FormattedMessage id="inventory" />
                        </Typography>
                      </Grid>
                      <Grid item xs={8.5} paddingX={2}>
                        <Typography
                          component="span"
                          fontWeight={500}
                          color={medication[0]?.quantity ? '#0962FF' : '#E53935'}
                        >
                          {medication[0]?.quantity || 0}
                        </Typography>
                        <Typography component="span" color="#263238" fontWeight={500}>
                          {medication[0]?.unit ? intl.formatMessage({ id: medication[0]?.unit }) : ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                );
              },
              propsWrapper: { xs: 3 },
            },
            ingredient: {
              type: () => {
                return (
                  <div style={{}}>
                    <Grid container paddingTop={0}>
                      <Grid item xs={4}>
                        <Typography>
                          <FormattedMessage id="therapy.detail.label.ingredientsLabel" />
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="subtitle1">{item?.ingredient}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                );
              },
              propsWrapper: { xs: 4, sx: { marginBottom: '20px' } },
            },
            dose: {
              type: () => {
                return (
                  <div>
                    <Grid container>
                      <Grid item xs={5}>
                        <Typography>
                          <FormattedMessage id="sale.dose" />
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="subtitle1">{item?.dose}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                );
              },
              propsWrapper: { xs: 2.5, sx: { marginBottom: '20px' } },
            },
            unit: {
              type: () => {
                return (
                  <div>
                    <Grid container>
                      <Grid item xs={5}>
                        <Typography>
                          <FormattedMessage id="therapy.detail.label.unitLabel" />
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="subtitle1">{item?.numeratorUnit}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                );
              },
              propsWrapper: { xs: 2.5, sx: { marginBottom: '20px' } },
            },
            price: {
              type: () => {
                return (
                  <div>
                    <Grid container>
                      <Grid item xs={3.5}>
                        <Typography>
                          <FormattedMessage id="drugUsage.price" />:
                        </Typography>
                      </Grid>
                      <Grid item xs={8.5}>
                        <Typography variant="subtitle1">{NumericFormatText(medicationPrice)}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                );
              },
              propsWrapper: { xs: 3, sx: { marginBottom: '20px' } },
            },
            dayNumber: {
              type: 'text-field',
              inputType: 'number',
              label: intl.formatMessage({ id: 'dental.prescription.label.dayNumber' }),
              placeholder: intl.formatMessage({ id: 'enter' }),
              propsWrapper: { xs: 1.5 },
              register: {
                maxLength: {
                  value: 4,
                  message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                },
                pattern: DECIMAL_NUMBER_REGEX,
              },
              onBlur: (e) => {
                if (e.target.value?.trim()) {
                  const value = e.target.value?.trim().replace(/[,]/g, '');
                  if (value.length <= 4) {
                    onChangeMedicationInfo({
                      ...valuesField,
                      dayNumber: value,
                      quantity: valuesField?.unitPerDay && valuesField?.unitPerDay * parseInt(value),
                    });
                  } else {
                    openSnackbar({
                      message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                      type: 'error',
                    });
                  }
                }
              },
            },
            multiply: {
              type: () => {
                return 'x';
              },
              propsWrapper: { xs: 0.5, sx: { marginTop: '25px', textAlign: 'center' } },
            },
            equalSign: {
              type: () => {
                return '=';
              },
              propsWrapper: { xs: 0.5, sx: { marginTop: '25px', textAlign: 'center' } },
            },
            unitPerDay: {
              type: 'text-field',
              inputType: 'number',
              label: intl.formatMessage({ id: 'dental.prescription.label.unitPerDay' }),
              placeholder: intl.formatMessage({ id: 'enter' }),
              propsWrapper: { xs: 1.5 },
              register: {
                maxLength: {
                  value: 16,
                  message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                },
                pattern: DECIMAL_NUMBER_REGEX,
              },
              onBlur: (e) => {
                if (e.target.value?.trim()) {
                  const value = e.target.value?.trim().replace(/[,]/g, '');
                  onChangeMedicationInfo({
                    ...valuesField,
                    unitPerDay: value,
                    quantity: valuesField?.dayNumber && valuesField?.dayNumber * parseInt(value),
                  });
                }
              },
            },
            quantity: {
              type: 'number-field',
              inputType: 'number',
              label: intl.formatMessage({ id: 'quantity' }),
              placeholder: intl.formatMessage({ id: 'enter' }),
              propsWrapper: { xs: 1.5 },
              register: {
                required: true,
                maxLength: {
                  value: 16,
                  message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                },
                pattern: DECIMAL_NUMBER_REGEX,
              },
              onBlur: (e) => {
                if (e.target.value?.trim()) {
                  onChangeMedicationInfo({
                    ...valuesField,
                    quantity: e.target.value?.trim().replace(/[,]/g, ''),
                    dayNumber: '',
                    unitPerDay: '',
                  });
                }
              },
              // defaultValue: 10,
            },
            intendedRoute: {
              type: 'auto-complete',
              label: intl.formatMessage({ id: 'therapy.detail.label.intendedRoute' }),
              placeholder: intl.formatMessage({ id: 'therapy.detail.label.intendedRouteEnter' }),
              options: getIndentedRouteOptions(valuesField?.medicationId),
              propsWrapper: { xs: 2.5 },
              onChange: (value) => {
                if (value) {
                  onChangeMedicationInfo({ ...valuesField, intendedRoute: value });
                }
              },
            },
            usage: {
              propsWrapper: { xs: 4 },
              type: () => {
                return (
                  <div>
                    <Autocomplete
                      freeSolo
                      value={valuesField?.usage}
                      disableClearable
                      options={DEFAULT_INSTRUCTION_KEY?.map((option) => intl.formatMessage({ id: option }))}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={intl.formatMessage({ id: 'encounterPrescription.instruction' })}
                          placeholder={intl.formatMessage({ id: 'noteEnter' })}
                          InputProps={{
                            ...params.InputProps,
                            type: 'search',
                          }}
                          onBlur={(e) => {
                            if (e.target?.value?.trim() && e.target?.value?.trim() !== valuesField?.usage)
                              onChangeMedicationInfo({ ...valuesField, usage: e.target.value?.trim() });
                          }}
                        />
                      )}
                      onChange={(e, value) => {
                        if (value && value !== valuesField?.usage)
                          onChangeMedicationInfo({ ...valuesField, usage: value?.trim() });
                      }}
                    />
                  </div>
                );
              },
            },
            delete: {
              unregister: true,
              type: () => {
                if (!readOnly)
                  return (
                    <IconButtonTitle
                      title="delete"
                      size="small"
                      disabled={readOnly || arrayData.value?.isPaid}
                      onClick={() => {
                        methodsArray?.remove(arrayData?.index);
                        onDelete(valuesField);
                      }}
                    >
                      <Delete />
                    </IconButtonTitle>
                  );
              },
              propsWrapper: {
                xs: true,
              },
            },
          };
        },
        ui: [
          {
            id: 'info',
            propsWrapper: { xs: true, paddingTop: 1 },
            fields: ['group', 'name', 'inventory', 'ingredient', 'dose', 'unit', 'price'],
          },
          {
            id: 'edit',
            propsWrapper: { xs: true },
            fields: ['dayNumber', 'multiply', 'unitPerDay', 'equalSign', 'quantity', 'intendedRoute', 'usage'],
          },
          {
            id: 'delete',
            propsWrapper: { xs: true, sx: { display: 'flex', alignItems: 'center' } },
            fields: ['delete'],
          },
        ],
        layout: ({ listElement, valuesField, fields }) => {
          return (
            <>
              {valuesField?.medicationList.length > 0 && (
                <Box mt={0} bgcolor={'#F4F8FF'} style={{ borderRadius: 4 }}>
                  <Grid container padding={1}>
                    <Grid item xs={11}>
                      <Box>{listElement[0]}</Box>
                    </Grid>
                    <Grid item xs={1} alignItems={'center'}>
                      <Box marginLeft={7}>{listElement[2]}</Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>{listElement[1]}</Box>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </>
          );
        },
      },
    },
  };
};

const ui: IUiFields = ({ formProps }) => {
  return [
    {
      id: 'info',
      fields: ['categoryOption', 'medicationOption'],
      paperProps: {
        sx: {
          border: 'none',
          bgcolor: '#F4F8FF',
          px: 2,
          py: 1,
        },
      },
      paper: true,
    },
    {
      id: 'medication',
      fields: ['medicationList'],
    },
  ];
};

export const prescriptionBox: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
  layout: ({ listElement, formProps }) => {
    return (
      <>
        {!formProps?.readOnly && listElement[0]}
        <Typography
          className="title"
          fontWeight={500}
          fontSize={'20px'}
          color={'#3B5999'}
          textTransform={'uppercase'}
          paddingTop={'15px'}
        >
          <FormattedMessage id="encounterDetail.prescription" />
        </Typography>
        {formProps?.formData?.medicationList?.length !== 0 ? (
          <Box mt={2}>{listElement[1]}</Box>
        ) : (
          <Box sx={{ textAlign: 'center' }}>
            <FormattedMessage id="prescription.box.no.data" />
          </Box>
        )}
      </>
    );
  },
};
