import Print from '@mui/icons-material/Print';
import { Box, Button, Divider, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactToPrint from 'react-to-print';
import {
  BE_DATE_FORMAT,
  FE_DATE_FORMAT,
  FE_TIME_DATE_FORMAT,
  PATIENT_ADDRESS_TYPE,
  PAYMENT_PLAN,
  some,
} from 'modules/common/constants';
import DialogCustom from 'modules/common/component/DialogCustom';
import HeaderPrintForm from 'modules/common/component/HeaderPrintForm';
import ImagePreview from 'modules/common/component/ImagePreview';
import { API_SERVER } from 'modules/common/api';
import { STORAGE_RESOURCE_TYPE } from 'modules/reception/constants';
import { useParams } from 'react-router';
import { RadioImageSizeImage } from 'modules/common/component/RadioImageSizeImage';
import 'modules/common/print.scss';
import { useFetch, useSpecializedDepartmentPIC } from 'modules/common/hook';
import { IMAGE_DEPARTMENT_CODE } from 'modules/common/apiConstants';
import { IndicationImage, IndicationRequest } from 'modules/schema';

const COMPACT_TYPE = 'IMAGE_DOCTOR_PRINT_COMPACT_TYPE';

const useStyles = makeStyles({
  print: {
    position: 'relative',
    height: '100%',
    display: 'block',
    padding: '24px 16px',
    [`@media print`]: {
      padding: 0,
    },
  },
});

interface TestReceiptContentProps {
  indicationRequest: some;
  compact: boolean;
  patient: some;
  indicationImage?: some;
}

const FormPrintContent = (props: TestReceiptContentProps) => {
  const { indicationRequest, compact, patient, indicationImage } = props;
  const { indicationRequestId } = useParams<{ indicationRequestId: string }>();
  const headDoctor = useSpecializedDepartmentPIC(IMAGE_DEPARTMENT_CODE);
  const { data: images } = useFetch(
    API_SERVER.storageResource.getList({
      type: STORAGE_RESOURCE_TYPE.therapyIndicationDiagnosticImage,
      referenceId: parseInt(indicationRequestId),
    }),
  );

  const header = React.useMemo(() => {
    return (
      <>
        <HeaderPrintForm>
          <Typography variant="h4" style={{ textTransform: 'uppercase' }}>
            <FormattedMessage id="imageDoctor.printHeader" />
            {indicationRequest?.serviceName}
          </Typography>
        </HeaderPrintForm>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ columnCount: 2 }}>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 160 }}>
                <FormattedMessage id="therapy.label.customer.code" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">{patient?.code?.split('-')[1]}</Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 160 }}>
                <FormattedMessage id="name" />
              </Typography>
              :&nbsp;
              <Typography variant="body1" style={{ textTransform: 'uppercase' }}>
                {patient?.name}
              </Typography>
            </Box>
            {!compact && (
              <>
                <Box display="flex">
                  <Typography variant="subtitle1" style={{ minWidth: 160 }}>
                    <FormattedMessage id="category" />
                  </Typography>
                  :&nbsp;
                  <Typography variant="body1">
                    <FormattedMessage id={PAYMENT_PLAN[indicationRequest?.paymentPlan]?.label} />
                  </Typography>
                </Box>
              </>
            )}
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 160 }}>
                <FormattedMessage id="address" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">
                {patient?.patientAddressList?.find((ele) => ele?.type === PATIENT_ADDRESS_TYPE.home)?.address}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 160 }}>
                <FormattedMessage id="birthdayShort" />
              </Typography>
              :&nbsp;{' '}
              <Typography variant="body1">{moment(patient.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}</Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 160 }}>
                <FormattedMessage id="gender" />
              </Typography>
              :&nbsp;{' '}
              <Typography variant="body1">
                {patient.gender && <FormattedMessage id={patient.gender?.toLowerCase()} />}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 160 }}>
                <FormattedMessage id="telephone" />
              </Typography>
              :&nbsp; <Typography variant="body1">{patient.mobilePhone}</Typography>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }, [
    compact,
    indicationRequest?.paymentPlan,
    indicationRequest?.serviceName,
    patient?.code,
    patient.dob,
    patient.gender,
    patient.mobilePhone,
    patient?.name,
    patient?.patientAddressList,
  ]);

  const encounterInfo = React.useMemo(() => {
    if (!compact) {
      return (
        <Box>
          <Box display="flex" gap={2}>
            <Box display="flex" flex={1}>
              <Typography variant="subtitle1" style={{ minWidth: 160 }}>
                <FormattedMessage id="doctorOfIndication" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">{indicationRequest?.picName}</Typography>
            </Box>
          </Box>

          <Box display="flex">
            <Typography variant="subtitle1" style={{ minWidth: 160 }}>
              <FormattedMessage id="timeExecuted" />
            </Typography>
            :&nbsp;{' '}
            <Typography variant="body1">
              {indicationRequest?.createdTime && moment(indicationRequest?.createdTime).format(FE_TIME_DATE_FORMAT)}
            </Typography>
          </Box>
        </Box>
      );
    }
  }, [compact, indicationRequest]);

  const footer = React.useMemo(() => {
    return (
      <>
        <Box style={{ pageBreakInside: 'avoid', marginTop: 12 }}>
          <Grid container>
            {compact ? (
              <Grid item xs={6} />
            ) : (
              <Grid item xs={6} textAlign="center">
                &nbsp;
                <Typography variant="h6">
                  <FormattedMessage id="printForm.imageDoctor2" />
                </Typography>
                <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                  <FormattedMessage id="medicalPrint.helperText" />
                </Typography>
                <Typography variant="body1">{headDoctor?.employeeFullName}</Typography>
              </Grid>
            )}
            <Grid item xs={6} textAlign="center">
              <Typography variant="body1" fontStyle="italic">
                <FormattedMessage
                  id={compact ? 'printForm.dateShort' : 'printForm.date'}
                  values={{
                    hour: moment().hour(),
                    min: moment().minute(),
                    day: moment().date(),
                    month: moment().month() + 1,
                    year: moment().year(),
                  }}
                />
              </Typography>
              <Typography variant="h6">
                <FormattedMessage id="imageDoctor.doctor" />
              </Typography>
              <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                <FormattedMessage id="medicalPrint.helperText" />
              </Typography>
              <Typography variant="body1">{headDoctor?.employeeFullName}</Typography>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }, [compact, headDoctor]);

  const testInfo = React.useMemo(() => {
    return (
      <>
        <Box>
          <Grid container spacing={1} justifyContent={'space-between'}>
            <Grid item>
              <Typography variant="h6">{indicationRequest?.serviceName}</Typography>
            </Grid>
            <RadioImageSizeImage>
              {(imageSize) => (
                <Grid item width={'100%'}>
                  <Grid container spacing={2} display="flex">
                    {images?.map((item, index) => {
                      return (
                        <Grid
                          item
                          key={index}
                          xs={imageSize}
                          textAlign="center"
                          display="flex"
                          flexDirection={'column'}
                          position="relative"
                        >
                          <Box textAlign="center">
                            <ImagePreview
                              alt=""
                              imageId={item.id}
                              style={{
                                maxHeight: imageSize === 6 ? 340 : imageSize === 3 ? 165 : 610,
                                width: imageSize === 12 ? '84%' : '100%',
                                objectFit: 'cover',
                              }}
                            />
                            <Typography variant="body2" color="textSecondary">
                              {item.description}
                            </Typography>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              )}
            </RadioImageSizeImage>
          </Grid>
        </Box>{' '}
        <Box style={{ pageBreakInside: 'avoid' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <FormattedMessage id="description" />
              </Typography>
            </Grid>
            {indicationImage?.examDepartment && (
              <Grid item xs={12}>
                <div
                  className="mce-editor-customs"
                  dangerouslySetInnerHTML={{ __html: indicationImage?.examDepartment }}
                ></div>
              </Grid>
            )}
          </Grid>
        </Box>{' '}
        <Box style={{ pageBreakInside: 'avoid' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <FormattedMessage id="conclusion" />
              </Typography>
            </Grid>
            {indicationImage?.conclusion && (
              <Grid item xs={12}>
                <div
                  className="mce-editor-customs"
                  dangerouslySetInnerHTML={{ __html: indicationImage?.conclusion }}
                ></div>
              </Grid>
            )}
          </Grid>
        </Box>
      </>
    );
  }, [images, indicationImage?.conclusion, indicationImage?.examDepartment, indicationRequest?.serviceName]);

  return (
    <>
      <Box style={{ pageBreakAfter: 'always' }}>
        {header}
        <Box paddingY={1} style={{ clear: 'both', pageBreakInside: 'avoid' }}>
          <Divider />
        </Box>
        {encounterInfo}
        {compact || (
          <Box paddingY={1} style={{ clear: 'both', pageBreakInside: 'avoid' }}>
            <Divider />
          </Box>
        )}

        {testInfo}
        {footer}
      </Box>
    </>
  );
};

interface Props {
  open: boolean;
  onClose?: () => void;
  indicationRequest: IndicationRequest;
  patient: some;
  indicationImage?: IndicationImage;
}

const PrintFormDialog = (props: Props) => {
  const { onClose, open, indicationRequest, patient, indicationImage } = props;
  const refPrintComponent = React.useRef(null);
  const classes = useStyles();
  const [compact, setCompact] = React.useState(localStorage.getItem(COMPACT_TYPE) === 'compact' || false);

  return (
    <DialogCustom
      className="camera-print-form-dialog"
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { maxWidth: 1200 },
      }}
      keepMounted={false}
      title={'medicalPrint.result'}
      footer={
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <FormControlLabel
            control={
              <Switch
                checked={compact}
                onChange={(e) => {
                  setCompact(e.target.checked);
                  localStorage.setItem(COMPACT_TYPE, e.target.checked ? 'compact' : 'expansion');
                }}
              />
            }
            label={<FormattedMessage id="printCompact" />}
          />
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary" startIcon={<Print />} style={{ minWidth: 182 }}>
                <FormattedMessage id="imageDoctor.print" />
              </Button>
            )}
            content={() => refPrintComponent.current}
          />
        </Box>
      }
    >
      <Box ref={refPrintComponent} className={classes.print}>
        <table width={'100%'}>
          <tbody>
            <FormPrintContent
              indicationRequest={indicationRequest}
              compact={compact}
              patient={patient}
              indicationImage={indicationImage}
            />
          </tbody>
        </table>
      </Box>
    </DialogCustom>
  );
};

export default PrintFormDialog;
