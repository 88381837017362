import { Chip, Grid } from '@mui/material';
import SchemaForm from '../../../../../common/SchemaForm';
import { DENTAL_DIAGNOSIS_BASIC_TYPE, some } from '../../../../../common/constants';
import React from 'react';
import useGeneralHook from '../../../../../common/hook/useGeneralHook';
import { useParams } from 'react-router';
import { API_SERVER } from '../../../../../common/api';
import { axiosThunk } from '../../../../../common/redux/axios';
import { fetchThunk } from '../../../../../common/redux/thunk';
import { setLoading } from 'modules/common/redux/commonReducer';
import { useDentalDetailStateSelector } from '../state';
import { useFetch } from 'modules/common/hook';

const MedicalHistory = () => {
  const { dispatch, intl, openSnackbar, appState } = useGeneralHook();
  const { dentalSessionId, patientId } = useParams<{ dentalSessionId: string; patientId: string }>();
  const readOnly = useDentalDetailStateSelector.use.readonly();

  const { data, revalidate } = useFetch(
    API_SERVER.dentalDiagnosisBasic.getList({
      type: DENTAL_DIAGNOSIS_BASIC_TYPE.MEDICAL_HISTORY.value,
      dentalExamId: dentalSessionId,
      patientId: patientId,
    }),
  );

  const onAddMedicalHistory = React.useCallback(
    async (illness: some) => {
      try {
        await dispatch(
          fetchThunk(
            API_SERVER.dentalDiagnosisBasic.create(),
            'post',
            {
              name: illness,
              type: DENTAL_DIAGNOSIS_BASIC_TYPE.MEDICAL_HISTORY.value,
              dentalExamId: dentalSessionId,
              patientId: patientId,
            },
            'application/json-patch+json',
          ),
        );
        revalidate();
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'createFail' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dentalSessionId, dispatch, intl, openSnackbar, patientId, revalidate],
  );

  const onDeleteMedicalHistory = React.useCallback(
    async (id: number) => {
      try {
        await dispatch(axiosThunk({ url: API_SERVER.dentalDiagnosisBasic.delete(id), method: 'delete' }));
        revalidate();
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'deleteFail' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, intl, openSnackbar, revalidate],
  );

  return (
    <Grid container sx={{ backgroundColor: 'white', padding: '12px 24px' }}>
      <Grid item xs={12}>
        <SchemaForm
          readOnly={readOnly}
          noHelperText={true}
          schema={{
            fields: ({ formProps, methods }) => {
              const { intl } = formProps || {};
              return {
                paymentMethod: {
                  type: 'select',
                  placeholder: intl.formatMessage({ id: 'dental.placeholder.selectIllness' }),
                  options: appState.common.medicalIllnessOptions,
                  onChange: (record) => {
                    if (record) {
                      onAddMedicalHistory(record);
                      methods.reset();
                    }
                  },
                },
              };
            },
          }}
          hideSubmitButton
        />
      </Grid>
      <Grid xs={12} sx={{ paddingBottom: '6px', paddingTop: '6px' }}>
        {data?.map((v) => {
          return (
            <Chip
              label={v?.name}
              onDelete={() => {
                onDeleteMedicalHistory(v?.id!);
              }}
              sx={{
                marginRight: '3px',
                marginBottom: '2px',
                '&.Mui-disabled': {
                  opacity: 'unset',
                  '.MuiChip-deleteIcon': {
                    display: 'none',
                  },
                },
              }}
              key={v.id}
              disabled={readOnly}
            />
          );
        })}
      </Grid>
      <Grid xs={12}>
        <SchemaForm
          readOnly={readOnly}
          noHelperText={true}
          schema={{
            fields: ({ valuesField, formProps, methods }) => {
              const { intl } = formProps || {};
              return {
                name: {
                  type: 'text-field',
                  placeholder: intl.formatMessage({ id: 'dental.placeholder.inputOther' }),
                  onKeyPress: (event) => {
                    if (event?.key === 'Enter') {
                      onAddMedicalHistory(valuesField?.name);
                      methods.reset();
                    }
                  },
                  onBlur: (event) => {
                    event.preventDefault();
                    onAddMedicalHistory(valuesField?.name);
                    methods.reset();
                  },
                },
              };
            },
          }}
          hideSubmitButton
        />
      </Grid>
    </Grid>
  );
};

export default MedicalHistory;
