import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { API_SERVER } from 'modules/common/api';
import { keyBy } from 'lodash';
import { StorageResource } from 'modules/schema';
import { DENTAL_DIAGNOSIS_STATUS, ADULT_TOOTH_MAP, CHILD_TOOTH_MAP } from '../common/constants';
import { useParams } from 'react-router';
import { styled } from '@mui/system';
import { useDentalDetailStateSelector } from '../state';
import { ODONTOGRAM_TYPES } from '../Odontogram/constants';
import { useFetch } from 'modules/common/hook';

const createOdontogramRow = (toothMap: typeof ADULT_TOOTH_MAP | typeof CHILD_TOOTH_MAP) =>
  [
    {
      type: ODONTOGRAM_TYPES.outsideSurface,
      position: toothMap.top,
    },
    {
      type: ODONTOGRAM_TYPES.occlusalSurface,
      position: toothMap.top,
    },
    {
      type: ODONTOGRAM_TYPES.insideSurface,
      position: toothMap.top,
    },
    {
      type: ODONTOGRAM_TYPES.insideSurface,
      position: toothMap.bottom,
    },
    {
      type: ODONTOGRAM_TYPES.occlusalSurface,
      position: toothMap.bottom,
    },
    {
      type: ODONTOGRAM_TYPES.outsideSurface,
      position: toothMap.bottom,
    },
  ] as const;

const ButtonPositionStyled = styled(Button)({
  padding: '5px',
  minWidth: 'unset',
  border: '1px solid #ECEFF1',
  fontSize: '12px',
  lineHeight: '16px',
  fontStyle: 'normal',
  letterSpacing: '0.4',
  color: '#000',
});

const ButtonPositionStyledError = styled(Button)({
  padding: '5px',
  minWidth: 'unset',
  border: '1px solid #ECEFF1',
  fontSize: '12px',
  lineHeight: '16px',
  fontStyle: 'normal',
  letterSpacing: '0.4',
  color: 'red',
});

const Odontogram = () => {
  const examTarget = useDentalDetailStateSelector.use.examTarget();
  const { patientId, dentalSessionId } = useParams<{ patientId; dentalSessionId }>();

  const { data: odontogramData } = useFetch(
    API_SERVER.dental.getListOdontogram({ dentalExamId: dentalSessionId, patientId }),
  );

  const toothMap = examTarget === 'ADULT' ? ADULT_TOOTH_MAP : CHILD_TOOTH_MAP;
  const odontogramRow = createOdontogramRow(examTarget === 'ADULT' ? ADULT_TOOTH_MAP : CHILD_TOOTH_MAP);

  const { data: s3ResourceData } = useFetch(
    API_SERVER.storageResource.getList({ type: examTarget === 'CHILD' ? 'DENTAL_CHILD' : 'DENTAL_ADULT' }),
    { revalidateOnMount: true, revalidateOnFocus: false },
  );

  const odontogramDataMapped = useMemo(
    () =>
      odontogramData?.reduce((result, current) => {
        const processingDiagnosisList = current.dentalDiagnosisList?.filter(
          (diagnosis) => diagnosis.status === DENTAL_DIAGNOSIS_STATUS.PROCESSING.value,
        );
        return {
          ...result,
          [current?.position || 0]: {
            ...current,
            insideSurfaceImages: processingDiagnosisList
              ?.filter((diagnosis) => diagnosis?.insideSurface)
              .map((diagnosis) => diagnosis.insideSurface),
            occlusalSurfaceImages: processingDiagnosisList
              ?.filter((diagnosis) => diagnosis?.occlusalSurface)
              .map((diagnosis) => diagnosis.occlusalSurface),
            outsideSurfaceImages: processingDiagnosisList
              ?.filter((diagnosis) => diagnosis?.outsideSurface)
              .map((diagnosis) => diagnosis.outsideSurface),
          },
        };
      }, {}),
    [odontogramData],
  );

  const s3ResourceDataMapped = useMemo(() => keyBy<StorageResource>(s3ResourceData, 'fileName'), [s3ResourceData]);

  return (
    <Box sx={{ marginTop: 1 }}>
      <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
        <Table sx={{ minWidth: 650, width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ padding: 0, borderBottom: 'unset' }}></TableCell>
              {toothMap.top.map((position) => (
                <TableCell align="center" key={position} sx={{ padding: 0, borderBottom: 'unset' }}>
                  {odontogramData?.find((item) => item.position === position)?.dentalDiagnosisList?.length === 0 ? (
                    <ButtonPositionStyled variant="outlined">{position}</ButtonPositionStyled>
                  ) : (
                    <ButtonPositionStyledError variant="outlined">{position}</ButtonPositionStyledError>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {odontogramRow.map(({ type, position }, index) => (
              <TableRow key={index}>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: '13px',
                    fontWeight: '500',
                    lineHeight: '24px',
                    letterSpacing: '0.1px',
                    borderBottom: 'unset',
                    padding: 0,
                  }}
                >
                  <FormattedMessage id={type.label} />
                </TableCell>
                {position?.map((positionIndex) => {
                  const teethInfo = odontogramDataMapped?.[positionIndex];
                  const defaultImageName = teethInfo?.[type.value];
                  const otherImageNames = teethInfo?.[`${type.value}Images`];
                  const defaultImage = s3ResourceDataMapped?.[defaultImageName];
                  const otherImages = otherImageNames?.map((imageName) => s3ResourceDataMapped?.[imageName]);
                  return (
                    <TableCell
                      align="center"
                      key={positionIndex}
                      sx={{
                        padding: 0,
                        borderBottom: 'unset',
                      }}
                    >
                      <Box position="relative" sx={{ transition: 'all 0.3s', cursor: 'pointer' }}>
                        <img src={defaultImage?.uri} alt={defaultImage?.fileName} />
                        {otherImages?.map((image) => (
                          <img
                            src={image?.uri}
                            alt={image?.fileName || ''}
                            style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%' }}
                          />
                        ))}
                      </Box>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
            <TableRow>
              <TableCell align="center" sx={{ padding: 1, borderBottom: 'unset' }}></TableCell>
              {toothMap.bottom.map((position) => (
                <TableCell align="center" key={position} sx={{ padding: 0, borderBottom: 'unset' }}>
                  {odontogramData?.find((item) => item.position === position)?.dentalDiagnosisList?.length === 0 ? (
                    <ButtonPositionStyled variant="outlined">{position}</ButtonPositionStyled>
                  ) : (
                    <ButtonPositionStyledError variant="outlined">{position}</ButtonPositionStyledError>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Odontogram;
